import React, {useEffect} from 'react';
import Footer from "../../core/layout/footer/footer";
import {events} from "./newsEventsModel";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

function NewsEventsView(props) {

    const {isXSView, isMobileView} = props;

    useEffect(() => {
    });
    const renderHeader = () => {
        return (
            <div className="pt-0 cover-background top-space-margin sm-pb-0 mb-3"
                 style={{
                     backgroundImage: `url(${s3BaseURL + '/Images/news/newsBanner.jpg'})`,
                 }}>
                <div className="page-container">
                    <div className="row align-items-end h-200px sm-h-100px ">
                        <div className="col-lg-9 col-md-6 position-relative text-left page-title-extra-large d-flex justify-content-left align-item-end">
                            <div className="justify-content-left header-img-text slideUpSlowly"> EVENTS</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderCards = () => {
        return (
            <div id="down-section" className='pt-4 '>
                <div className="page-container">
                    <div className="row m-0 p-0">
                        <div className="col-12 px-0 pb-3">
                            <div className='p-0 m-0 services-title'>We are present at the following events in 2025.
                                Please visit us, we would be very keen to speak with you.
                            </div>

                            {events.map((event, index) => (
                                <div key={index} className="py-3 pt-3">
                                    <div className='services-title'>{event.title}</div>
                                    <div className='mb-1'>
                                        <div className='news-event-subHeading'>{event.date}</div>
                                        <div className='news-event-subHeading'>{event.location}</div>
                                    </div>
                                    <a href={event.link}
                                       className="news-event-readmoreCSS"
                                       target="_blank">
                                        Read more
                                        <i className="fa-icon-alignment fa-solid fa-arrow-right fa-xs ps-1"/>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>
        )
    };

    return (
        <div>
            {renderHeader()}
            {renderCards()}
            <Footer {...props}/>
        </div>
    );
}

export default NewsEventsView;
