import React, {useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import ChemInformaticsView from "./chemInformaticsView";


const ChemInformatics = (props) => {
    const [enquireModel, setEnquireModel] = useState({
        isModelOpen:false
    });

    const openModel=()=>{
        setEnquireModel((prevState) => ({
            ...prevState,
            isModelOpen: true
        }));
    };

    const closeModal=()=>{
        setEnquireModel((prevState) => ({
            ...prevState,
            isModelOpen: false
        }));
    };

    return (
        <ChemInformaticsView
            {...props}
            {...enquireModel}
            openModel={openModel}
            closeModal={closeModal}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(withTranslation('translations')(ChemInformatics));
