
import {httpPost} from "../utils/httpHelper";

export const sendContact = (data) => {
    let url = '/service/publicWebsite/contactUs/contact';

    return httpPost(url,data)
        .then((res) => {
            return res;
        })
};

