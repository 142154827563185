import React, {Component} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {connect} from "react-redux";

class DLLoaderView extends Component {
    render() {

        const {type,isVisible,systemError, appLoader, customStyle, thickness, color} = this.props;

        if(systemError.isVisible){
            return  null;
        }

        if(isVisible || appLoader.isVisible) {
            if(type === 'inline') {
                return (
                    <div>
                        <CircularProgress color={color}
                                          thickness={thickness}
                                          style={{
                                              ...customStyle,
                                          }}
                                          disableShrink={true}
                                          variant={"indeterminate"}
                        />
                    </div>
                );
            }

            return (
                <div className='dl-app-loader-container'>
                    <CircularProgress color={'inherit'}
                                      thickness={6}
                                      style={{
                                          width: '50px',
                                          height: '50px',
                                      }}
                                      disableShrink={true}
                                      variant={"indeterminate"}
                    />
                    {/*<div className="dl-app-loader-container">*/}
                    {/*  <div className="dl-app-loader" />*/}
                    {/*</div>*/}
                </div>
            );
        }
        return  null;

    }
}

const mapStateToProps = (state) => ({
    appLoader: state.appState.appLoader,
    systemError: state.appState.systemError
});


export default connect(mapStateToProps, {})(DLLoaderView);
