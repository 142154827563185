import React from 'react';
import Footer from "../../core/layout/footer/footer";
import EnquireNow from "../dataCuration/component/enquireNow";
import careersMobileImage from "../../assets/img/careersMobileImage.png";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL + '/Images';

const ChemInformaticsView = (props) => {
    const {isModelOpen, openModel, closeModal, isMobileView}=props;
    const renderHeaderUI = () => {
        return (
            <div className="pt-0 cover-background top-space-margin sm-pb-0 mb-3"
                 style={{
                     backgroundImage:`url(${isMobileView ? careersMobileImage : s3BaseURL+'/services/servicesBanner.jpg'})`
                 }}>
                <div className="page-container">
                    <div className="row align-items-end h-200px sm-h-100px ">
                        <div className="col-lg-9 col-md-6 position-relative text-left page-title-extra-large d-flex justify-content-left align-item-end">
                            <div className="justify-content-left header-img-text slideUpQuickly"> Cheminformatics</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderCheminformatics = () => {
        return (
            <div>
                <div className='services-title pt-3'>Cheminformatics</div>
                <div className='mb-1 mt-2'>
                    <div className='news-event-subHeading '>Application of Cheminformatic techniques has become
                        imperative in the field of drug discovery and development.
                        These techniques enable efficient management and analysis of enormous data generated during drug
                        discovery which covers wide chemical and biological space.
                        Cheminformatic solutions offered by Evolvus are crucially important for hit identification, lead
                        identification and lead optimisation.
                    </div>
                </div>

                <div className='services-title mt-3'>Pharmacophore Modelling</div>
                <div className='mb-1 mt-2'>
                    <div className='news-event-subHeading '>Within the last few years, pharmacophore modelling has
                        become the most important ligand-based drug design technique.
                        We at Evolvus, use various commercial as well as in-house softwares for developing pharmacophore
                        models. Generated models are validated using in combo approaches.
                        We also undertake pseudo-receptor modelling, particularly for GPCRs, which involves generation
                        of a target protein's active site based on structural requirements complementary to the
                        generated pharmacophore.
                    </div>
                </div>

                <div className='services-title mt-3'>Scaffold Hopping</div>
                <div className='mb-1 mt-2'>
                    <div className='news-event-subHeading '>
                        Evolvus offers advanced computational approaches for hopping from one scaffold to another for
                        modifying drug selectivity and affinity profile, as well as for optimising physicochemical and
                        ADMET properties.
                        By its virtue to jump into a new chemical space, scaffold-hopping inherits a strong potential
                        for generation of intellectual property.
                    </div>
                </div>

                <div className='services-title mt-3'>3D-QSAR</div>
                <div className='mb-1 mt-2'>
                    <div className='news-event-subHeading'>
                        Using different commercial 3D-QSAR packages, a team of experienced computational chemists
                        performs exhaustive analysis of hit or lead series.
                        Results of this analysis furnish decisive inputs which could be effectively used for lead
                        optimisation.
                        Based on QSAR results, we map regions of parent compounds liable for structural manipulation,
                        identify probable substituents, design new entities and predict their activities.
                    </div>
                </div>

                <div className='services-title mt-3'> In Silico ADME-TOX Prediction</div>
                <div className='mb-1 mt-2'>
                    <div className='news-event-subHeading'>
                        Evolvus is actively engaged in development of models for in silico prediction of ADME-TOX
                        parameters.
                        These models serve as efficient and cost-effective techniques for screening large compound
                        libraries prior to synthesis, wherein those compounds with unfavourable properties could be
                        filtered out very initially.
                    </div>
                </div>

                <div className='services-title mt-3'> Compound Library Design</div>
                <div className='mb-1 mt-2'>
                    <div className='news-event-subHeading '>
                        Compound library design enables rational selection of compounds for the purpose of synthesis and
                        biological evaluation. An array of computational methods including similarity/diversity
                        assessment, QSAR modelling, pharmacophore modelling, scaffold hopping, ADME/TOX modelling and
                        virtual screening, is utilised to design focused,
                        diverse as well as target family-oriented libraries for a particular drug design project. With
                        the view of maximising the possibility of intellectual property generation, exhaustive patent
                        search of designed library is also undertaken.
                    </div>
                </div>

                <div className='services-title mt-3'> Molecular Informatics</div>
                <div className='mb-1 mt-2'>
                    <div className='news-event-subHeading'>
                        Application of computational techniques has now become imperative in any life-science research.
                        We utilise a range of contemporary approaches for small
                        molecular/macromolecular modelling to provide crucial inputs for your research.
                    </div>
                </div>

                <div className='services-title mt-3'> Molecular Modelling</div>
                <div className='mb-1 pb-1 mt-2'>
                    <div className='news-event-subHeading'>
                        Our molecular modelling services are of prime importance in the field of drug discovery. We
                        offer different computational solutions for NCEs of therapeutic importance. Our modelling tool
                        also normalises difficulties faced in computational chemistry and data processing. We have a
                        target-centric virtual library of chemically diverse compounds which can be used for identifying
                        novel scaffolds against a target of interest.
                    </div>

                    <div className='news-event-subHeading'><br/>
                        <div className='text-header'>
                            Key Features:
                        </div>
                        · Pharmacophore development<br/>
                        · QSAR/QSPR model development<br/>
                        · Molecular dynamics simulation of proteins<br/>
                        · Target active site mapping<br/>
                        · De novo drug design<br/>
                        · Molecular docking<br/>
                        · Pharmacokinetic Modelling<br/>
                    </div>
                </div>

                <div className='news-event-Heading mt-3'>
                    Molecular Docking
                </div>
                <div className='mb-1'>
                    <div className='news-event-subHeading'>
                        The molecular docking service rendered by Evolvus helps clients during hit identification and
                        lead optimisation process. We help establish and understand association between small molecule
                        ligands and their target proteins or nucleic acids. Our docking tool has a friendly GUI and
                        experimentally validated scoring algorithm which helps achieve high correlation between docking
                        score and evaluated binding affinities. The docking tool also provides comprehensively all the
                        features required for 3D-structure optimisation of the raw crystal structure of the target
                        protein. Another prominent feature of this software is its ability to derive statistically
                        validated structure based pharmacophore model.
                    </div>

                    <div className='news-event-subHeading'><br/>
                        <div className='text-header'>
                            Key Features:
                        </div>
                        ·          Agonism/Antagonism prediction<br/>
                        ·          Binding pose prediction<br/>
                        ·          Calculation of protein partial charge<br/>
                        ·          Protein optimisation<br/>
                        ·          Missing side-chain/residues incorporation<br/>
                        ·          Prediction of binding free energy<br/>
                        ·          Parameter normalisation<br/>
                        ·          Predicting ligand-protein interaction<br/>
                        ·          Calculating interaction energies<br/>
                        ·          Structure based pharmacophore generation<br/>
                        ·          In silico HTS protocol<br/>
                    </div>
                </div>
            </div>
        )
    };


    const renderUI = () => {
        return (
            <div>
                {renderHeaderUI()}
                <div className="pb-0">
                    <div className="page-container mt-3 mb-4">
                        {renderCheminformatics()}
                    </div>
                </div>

            </div>
        )
    };

    return (
        <div>

            {renderUI()}
            <Footer {...props}/>
            <div className="floating-tab cursor" onClick={()=>{openModel()}}>
                <div className="popup-with-move-anim" >
                    <div className="tab-text">Enquire Now</div>
                </div>
            </div>
            {isModelOpen &&
            <EnquireNow
                closeModal={closeModal}
            />
            }
        </div>
    );
};

export default ChemInformaticsView;