import React, {useEffect, useRef} from 'react';
import Footer from "../core/layout/footer/footer";
import BackImage from "./img/contactUs.png"
import Call from "./img/call.png"
import Location from "./img/location.png"
import Mail from "./img/mail.png"
import Frank from "./img/frank.png"
import Sym from "./img/sym.jpg"
import Usa from "./img/usa.png"
import {isEmpty} from "../utils/validations";

const ContactUSView = (props) => {
    const {handleChanges, handleSendMsg, onBlurContent, errors, isMobileView, scrollToFieldId, onFormChange,
        isXSView} = props;

    const targetRef = useRef(null);

    useEffect(() => {
        if (!isEmpty(scrollToFieldId)) {
            const element = document.getElementById(scrollToFieldId);
            if (!isEmpty(element)) {
                element.scrollIntoView({behavior: 'smooth', block: 'center'});
                setTimeout(()=>{
                    onFormChange('')
                }, 1000)
            }
        }
    }, [scrollToFieldId]);

    const renderHeader = () => {
        return (
            <div className="pt-0 cover-background sm-pb-0 section-divider"
                 style={{
                     backgroundImage: `url(${BackImage})`,
                     zIndex: 500
                 }}>
                <div className="page-container">
                    <div className="row align-items-end">
                        <div className="slideUpSlowly col-lg-9 col-md-6 text-left">
                            <div className="header-img-text">
                                Contact <b>
                                <div className='d-inline' style={{color: 'var(--success-light)'}}>Us</div>
                            </b>
                            </div>
                        </div>
                        {!isMobileView && <div
                            className="col-lg-1 col-md-3 text-right">
                            <img src={Call} alt="Call Icon"
                                 className=" mb-5 mb-lg-3 contact-icon"/>
                        </div>}

                        {!isMobileView && <div
                            className="col-lg-1 col-md-3 text-right">
                            <img src={Location} alt="Location Icon" className="mb-5 mb-lg-3 contact-icon"/>
                        </div>}

                        {!isMobileView && <div
                            className="col-lg-1 col-md-3 text-right">
                            <img src={Mail} alt="Mail Icon" className="mb-5 mb-lg-3 contact-icon"/>
                        </div>}
                    </div>
                </div>
            </div>
        );
    };

    const renderLocations = () => {
        return (
            <div className='page-container'>
                <div className='section-divider'>
                    <div className="row">
                        <div className="col-lg-4 col-xl-4 mb-md-5 section-divider pe-lg-5 pe-md-0">
                            <div className="text-dark-gray slideUp section-title mb-4"
                                 style={{
                                     lineHeight: '40px'
                                 }}>
                                Visit us at one of our different locations.
                            </div>
                            <div className="slideUpSlowly text-gray pe-lg-3 pe-md-0">
                                Visit us at one of our various locations, where our expert team is ready to assist
                                you with all your needs. Whether you're nearby or traveling, we ensure consistent
                                quality and service across all our branches. Discover convenience and personalized
                                care no matter where you are!
                            </div>
                        </div>

                        <div className="col-lg-8 col-xl-8">
                            <div className={"row row-cols-1 row-cols-sm-3 justify-content-md-start justify-content-lg-center " + (isMobileView ? "" : "pe-5")}>
                                {/* India Location */}
                                <div className="col slideUp">
                                    <div className="rounded-top hover-box border-bottom overflow-hidden h-100" >
                                        <div className="image">
                                            <img src={Sym}
                                                 alt="India location"
                                                 height='auto'
                                                 width='100%'/>
                                        </div>
                                        <div className="bg-white position-relative">
                                            <div>
                                                <div className={isXSView ? "my-2 pe-2 header" :"mt-2 pe-2 header"}>
                                                    INDIA
                                                    <div className='dot-class'>•</div>
                                                </div>
                                                <div
                                                    className="d-flex align-items-center text-dark-gray mb-4">
                                                    Symphony IT park, Nanded city, Sinhgad Road Pune-411041
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col slideUp">
                                    <div className="rounded-top hover-box border-bottom overflow-hidden h-100">
                                        <div className="image">
                                            <img src={Usa} alt="Usa location" height='auto' width='100%'/>
                                        </div>
                                        <div className="bg-white position-relative">
                                            <div>
                                                <div
                                                    className={isXSView ? "my-2 pe-2 header" :"mt-2 pe-2 header"}>
                                                    USA
                                                    <div className='dot-class'>•</div>
                                                </div>
                                                <div
                                                    className="d-flex align-items-center text-dark-gray mb-4">
                                                    2 Forsythia Drive, Nashua, NH - 03062
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col slideUp">
                                    <div className="rounded-top hover-box border-bottom overflow-hidden h-100">
                                        <div className="image">
                                            <img src={Frank} alt="Germany location" height='auto' width='100%'/>
                                        </div>
                                        <div className="bg-white position-relative">
                                            <div>
                                                <div
                                                    className={isXSView ? "my-2 pe-2 header" :"mt-2 pe-2 header"}>
                                                    GERMANY
                                                    <div className='dot-class'>•</div>
                                                </div>
                                                <div
                                                    className="d-flex align-items-center text-dark-gray mb-4">
                                                    Altenhöferallee 3, 60438 Frankfurt am Main
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderForm = () => {

        return (
            <div ref={targetRef} className=" page-container position-relative pt-sm-0 mb-5">
                <div  className="overflow-hidden position-relative pt-1 pt-sm-1">
                    <div className="row align-items-lg-start justify-content-start mb-2 mb-sm-3">
                        <div className=" col-lg-4 section-divider">
                            <div className="text-dark-gray section-title">We are here for you</div>
                        </div>
                        <div className="col-xxl-8 col-xl-7 col-lg-8">
                            <form action="#" method="post"
                                  onSubmit={(e) => e.preventDefault()}>
                                <div className="row row-cols-1 row-cols-sm-2 justify-content-center">
                                    <div className="col">
                                        <label htmlFor="name"
                                               className="form-label text-dark-gray mb-0 header">
                                            Enter your name*
                                        </label>
                                        <div className="position-relative form-group mb-4 text-gray " >
                                            <input
                                                className={"ps-0 border-0 border-bottom rounded-0 shadow-none bg-transparent form-control required text-gray " + (isEmpty(errors.name) ? '' : 'border-danger')}
                                                id="name"
                                                type="text"
                                                value={props.name || ''}
                                                name="name"
                                                placeholder=""
                                                onBlur={() => {
                                                    onBlurContent('name')
                                                }}
                                                style={{
                                                    borderBlockColor: !isEmpty(errors.name) ? 'red' : '#F1F1F1',
                                                }}
                                                onChange={(e) => {
                                                    handleChanges('name', e.target.value)
                                                }}
                                            />
                                            {!isEmpty(errors.name) &&
                                            <div id = 'error-field-name'
                                                 className='theme-font-content-xs text-danger'>{errors.name}</div>}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <label htmlFor="email"
                                               className="form-label text-dark-gray mb-0 header">
                                            Email address*
                                        </label>
                                        <div className="position-relative form-group mb-4">
                                            <input
                                                className={"ps-0 border-0 border-bottom rounded-0 shadow-none bg-transparent form-control required text-gray " + (isEmpty(errors.email) ? '' : 'border-danger')}
                                                id="email"
                                                type="email"
                                                name="email"
                                                value={props.email || ''}
                                                placeholder=""
                                                onBlur={() => {
                                                    onBlurContent('email')
                                                }}
                                                style={{
                                                    borderBlockColor: !isEmpty(errors.email) ? 'red' : '#F1F1F1',
                                                }}
                                                onChange={(e) => {
                                                    handleChanges('email', e.target.value)
                                                }}
                                            />
                                            {!isEmpty(errors.email) &&
                                            <div id='error-field-email'
                                                 className='theme-font-content-xs text-danger'>{errors.email}</div>}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <label htmlFor="phone"
                                               className="form-label text-dark-gray mb-0 header">
                                            Phone number
                                        </label>
                                        <div className="position-relative form-group mb-4">
                                            <input
                                                className="ps-0 border-0 border-bottom rounded-0 shadow-none bg-transparent form-control text-gray"
                                                id="phone"
                                                type="tel"
                                                value={props.mobile}
                                                name="phone"
                                                placeholder=""
                                                onChange={(e) => {
                                                    handleChanges('mobile', e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <label htmlFor="subject"
                                               className="form-label text-dark-gray mb-0 header">
                                            Subject
                                        </label>
                                        <div className="position-relative form-group mb-4">
                                            <input
                                                className="ps-0 border-0 border-bottom rounded-0 shadow-none bg-transparent form-control text-gray"
                                                id="subject"
                                                type="text"
                                                value={props.subject}
                                                name="subject"
                                                placeholder=""
                                                onChange={(e) => {
                                                    handleChanges('subject', e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row row-cols-1 justify-content-center'>
                                    <div className="col-12 mb-4">
                                        <label htmlFor="message"
                                               className="form-label text-dark-gray mb-0 header">
                                            Your message
                                        </label>
                                        <div className="position-relative form-group form-textarea mb-0">
                                            <textarea
                                                className="ps-0 border-0 border-bottom rounded-0 shadow-none bg-transparent form-control text-gray"
                                                name="comment"
                                                placeholder=""
                                                value={props.massage}
                                                rows="4"
                                                onChange={(e) => {
                                                    handleChanges('massage', e.target.value)
                                                }}
                                                style={{ resize: 'none' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xxl-6 col-lg-7 col-md-8 section-divider">
                                        <div className="mb-0 text-center text-md-start text-gray"
                                             style={{fontSize: 'var(--font-size-xxs)', lineHeight: 'var(--font-size-lg)'}}>
                                            We are committed to protecting your privacy. We will never collect
                                            information about you without your explicit consent.
                                        </div>
                                    </div>
                                    <div className="col-xxl-6 col-lg-5 col-md-4 text-center text-md-end sm-mt-25px">
                                        <input type="hidden" name="redirect"/>
                                        <button
                                            className="btn with-rounded gradient-send-btn btn-box-shadow text-transform-none submit"
                                            type="submit"
                                            onClick={() => {
                                                handleSendMsg()
                                            }}
                                        >
                                            Send message
                                        </button>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-results mt-3 pt-1 d-none"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderUi = () => {
        return (
            <div>
                {renderHeader()}
                {renderLocations()}
                {renderForm()}
            </div>
        )
    };

    return (
        <div>
            {renderUi()}
            <Footer {...props}/>
        </div>
    );
};

export default ContactUSView;
