import React, { useEffect, useRef, useState } from "react";
import Footer from "../core/layout/footer/footer";
import {isEmpty} from "../utils/validations";
import DLLoader from "../core/components/Loader/loader";

let s3BaseURL =
    process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL + "/Images/careers";

function CareersView(props) {
    const {
        isOpenFirstDiv,
        isOpenSecondDiv,
        onChangeValue,
        onBlurContent,
        onFormChange,
        handleSend,
        errors,
        handleAttachmentUploadEvent,
        isCVAttached,
        isShowLoader,
        scrollToFieldId,
        isXLView
    } = props;

    const [isVisible, setIsVisible] = useState([false, false, false]);

    const elementRefs = [useRef(null), useRef(null), useRef(null)];

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                const index = elementRefs.findIndex(
                    (ref) => ref.current === entry.target
                );
                if (index !== -1 && !isVisible[index]) {
                    setIsVisible((prev) => {
                        const newVisibility = [...prev];
                        newVisibility[index] = entry.isIntersecting;
                        return newVisibility;
                    });
                }
            },
            { threshold: 0.5 }
        );

        elementRefs.forEach((ref) => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        return () => {
            elementRefs.forEach((ref) => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            });
        };
    }, [isVisible]);

    useEffect(() => {
        if (!isEmpty(scrollToFieldId)) {
            const element = document.getElementById(scrollToFieldId);
            if (!isEmpty(element)) {
                element.scrollIntoView({behavior: 'smooth', block: 'center'});
                setTimeout(()=>{
                    onFormChange('scrollToFieldId', '')
                }, 1000)
            }
        }
    }, [scrollToFieldId]);

    const renderHeader = () => {
        return (
            <div
                className="pt-0 cover-background top-space-margin sm-pb-0 mb-3"
                style={{
                    backgroundImage: `url(${s3BaseURL + "/careersBanner.jpg"})`,
                }}>
                <div className="page-container">
                    <div className="row align-items-end h-200px sm-h-100px ">
                        <div className="col-lg-9 col-md-6 position-relative text-left page-title-extra-large d-flex justify-content-left align-item-end">
                            <div className="justify-content-left header-img-text slideUpSlowly">
                                {" "}
                                Careers
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderTitle = () => {
        return (
            <div className="position-relative bg-white fade-in-without-hidden pt-4">
                <div className="page-container">
                    <div className="row align-items-center justify-content-center align-content-center position-relative">
                        <div className="col-xl-12 col-lg-12 d-flex align-items-center justify-content-">
                            <div className="section-title mb-4">Current Openings</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderFormUI = () => {
        return (
            <div className="section-divider">
                <div className="mt-5 mt-md-0 position-relative bg-white">
                    <div className="">
                        <div className="page-container">
                            <div className='border-top pt-4'/>
                            <div className="col text-center mt-3 mt-md-0">
                                <div className="primary-text display-4 mb-4 mb-md-5">
                                    Send Your CV
                                </div>
                            </div>
                        </div>
                        <div className="row m-0 p-0 align-items-center justify-content-center position-relative page-container">
                            <div className="col-xl-12 col-lg-12 block-container">
                                <div className={isXLView ? "row px-5 mx-3" :"row"}>
                                    <div className="col-md-6 mb-4 pb-1"
                                    id={'name'}>
                                        <input
                                            className={
                                                (errors.name !== "" ? "error-border" : "") +
                                                " box-shadow form-control w-100 "
                                            }
                                            type="text"
                                            name="name"
                                            placeholder="Your full name*"
                                            value={props.name}
                                            onBlur={() => {
                                                onBlurContent("name");
                                            }}
                                            onChange={(e) => {
                                                onChangeValue("name", e.target.value);
                                            }}
                                        />
                                        {!isEmpty(errors.name) && (
                                            <div
                                                className="text-danger"
                                                style={{ fontSize: "var(--font-size-xs)" }}
                                            >
                                                {errors.name}
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-6 mb-4 pb-1"
                                         id={'email'}>
                                        <input
                                            className={
                                                (errors.email !== "" ? "error-border" : "") +
                                                " box-shadow form-control w-100"
                                            }
                                            type="email"
                                            name="email"
                                            placeholder="Your email*"
                                            value={props.email}
                                            onBlur={() => {
                                                onBlurContent("email");
                                            }}
                                            onChange={(e) => {
                                                onChangeValue("email", e.target.value);
                                            }}
                                        />
                                        {!isEmpty(errors.email) && (
                                            <div
                                                className="text-danger"
                                                style={{ fontSize: "var(--font-size-xs)" }}
                                            >
                                                {errors.email}
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-lg-3 col-md-6 mb-4 pb-1">
                                        <div className="dropdown w-100 text-gray">
                                            <button
                                                className="btn box-shadow dropdown-toggle w-100 text-start d-flex align-items-center justify-content-between text-gray text-truncate"
                                                type="button"
                                                id="dropdownMenuButton"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                {props.region || "Select Region"}
                                                <i className="fa-solid fa-chevron-down text-gray"></i>
                                            </button>
                                            <ul
                                                className="dropdown-menu w-100"
                                                aria-labelledby="dropdownMenuButton"
                                            >
                                                <li>
                                                    <button
                                                        className="dropdown-item text-gray"
                                                        onClick={() => onFormChange("region", "India")}
                                                    >
                                                        India
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        className="dropdown-item text-gray"
                                                        onClick={() => onFormChange("region", "USA")}
                                                    >
                                                        USA
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        className="dropdown-item text-gray"
                                                        onClick={() =>
                                                            onFormChange("region", "Germany")
                                                        }
                                                    >
                                                        Germany
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 mb-4 pb-1">
                                        <div className="dropdown w-100 text-gray">
                                            <button
                                                className="btn box-shadow dropdown-toggle w-100 text-start d-flex align-items-center justify-content-between text-gray text-truncate"
                                                type="button"
                                                id="dropdownMenuButton"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                {props.functions || "Select Function"}
                                                <i className="fa-solid fa-chevron-down text-gray"></i>
                                            </button>
                                            <ul
                                                className="dropdown-menu w-100 overflow-auto"
                                                aria-labelledby="dropdownMenuButton"
                                                style={{height: '131px'}}
                                            >
                                                <li>
                                                    <button
                                                        className="dropdown-item text-gray"
                                                        onClick={() =>
                                                            onFormChange("functions", "Biology")
                                                        }
                                                    >
                                                        Biology
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        className="dropdown-item text-gray"
                                                        onClick={() =>
                                                            onFormChange("functions", "Chemistry")
                                                        }
                                                    >
                                                        Chemistry
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        className="dropdown-item text-gray"
                                                        onClick={() =>
                                                            onFormChange("functions", "Informatics")
                                                        }
                                                    >
                                                        Informatics
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        className="dropdown-item text-gray text-truncate"
                                                        onClick={() =>
                                                            onFormChange("functions", "Marketing/Sales")
                                                        }
                                                    >
                                                        Marketing/Sales
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        className="dropdown-item text-gray"
                                                        onClick={() =>
                                                            onFormChange("functions", "Corporate")
                                                        }
                                                    >
                                                        Corporate
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        className="dropdown-item text-gray text-truncate"
                                                        onClick={() =>
                                                            onFormChange("functions", "Human Resources")
                                                        }
                                                    >
                                                        Human Resources
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        className="dropdown-item text-gray text-truncate"
                                                        onClick={() =>
                                                            onFormChange(
                                                                "functions",
                                                                "Application Scientist"
                                                            )
                                                        }
                                                    >
                                                        Application Scientist
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 mb-4 pb-1 text-gray"
                                         id={'document'}>
                                        <input
                                            id="file-input"
                                            ref={props.fileInputRef}
                                            className={
                                                isCVAttached ? "w-100 error-border" : "border w-100"
                                            }
                                            style={{ padding: "12px 25px" }}
                                            type="file"
                                            accept=".pdf"
                                            onChange={(e) => {
                                                handleAttachmentUploadEvent(e);
                                            }}
                                        />
                                        {isCVAttached && (
                                            <div
                                                className="text-danger"
                                                style={{ fontSize: "var(--font-size-xs)" }}
                                            >
                                                {"Please upload CV"}
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-12 mb-4 pb-1">
												<textarea
                                                    value={props.massage}
                                                    className="box-shadow form-control"
                                                    cols="40"
                                                    rows="4"
                                                    name="comment"
                                                    placeholder="Your message"
                                                    onChange={(e) =>
                                                        onFormChange("massage", e.target.value)
                                                    }
                                                ></textarea>
                                    </div>
                                    <div className="col-xl-6 col-md-7">
                                        <div className="mb-0 text-center text-md-start w-100 w-md-100 text-gray section-text">
                                            We are committed to protecting your privacy. We will
                                            never collect information about you without your
                                            explicit consent.
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-md-5 text-center text-md-end pt-2 pt-lg-0 mt-0 px-0">
                                        <button
                                            onClick={() => {
                                                handleSend();
                                            }}
                                            className="btn text-transform-none"
                                        >
                                            <div
                                                className="gradient-btn mt-0"
                                                style={{
                                                    borderRadius: 4,
                                                    padding: "18px 34px",
                                                }}
                                            >
                                                <i className="fa-regular fa-calendar text-white pe-2 pb-1" />
                                                Send
                                            </div>
                                        </button>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-results mt-3 pt-1 d-none"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderDescriptionUI = () => {
        return (
            <div className="pt-3 page-container section-divider ">
                <div className="col-12 col-xl-12 col-lg-12 col-md-12">
                    <div
                        className="accordion accordion-style-01"
                        id="accordion-style-01"
                        data-active-icon="fa-angle-down"
                        data-inactive-icon="fa-angle-right"
                    >
                        <div className="accordion-item bg-white">
                            <div className="accordion-header">
                                <button
                                    data-bs-toggle="collapse"
                                    data-bs-target="#accordion-style-01-01"
                                    aria-expanded="true"
                                    data-bs-parent="#accordion-style-01"
                                    className="w-100 border-0 bg-white d-flex justify-content-between align-items-center px-0"
                                    onClick={() => {
                                        onChangeValue("isOpenFirstDiv", !isOpenFirstDiv);
                                    }}>
                                    <div className="accordion-title position-relative d-flex align-items-center section-sub-title primary-text mb-0 pe-3 text-start">
                                        Scientific Data Curator or Data Scientist (Life Sciences) -
                                        job code - 111002
                                    </div>
                                    <div className="">
                                        {!isOpenFirstDiv ? (
                                            <i className="fa-solid fa-angle-down icon-small"
                                               onClick={() => {
                                                   onChangeValue("isOpenFirstDiv", false);
                                               }}
                                            />
                                        ) : (
                                            <i className="fa-solid fa-angle-right icon-small"
                                               onClick={() => {
                                                   onChangeValue("isOpenFirstDiv", true);
                                               }}
                                            />
                                        )}
                                    </div>
                                </button>
                            </div>
                            <div
                                id="accordion-style-01-01"
                                className="accordion-collapse collapse show"
                                data-bs-parent="#accordion-style-01"
                            >
                                <div className=" pe-5">
                                    <div className="row mb-4 pb-1 pt-3">
                                        <div className="col-12">
                                            <div className="position-relative">
                                                <div className="w-100 d-block bg-black"
                                                    style={{ height: "1.5px" }}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div ref={elementRefs[0]}>
                                        <div className="text-gray">
                                            <div className="d-inline text-black d-block mt-3 pt-1 pb-2 block-sub-title">
                                                Job Description:
                                            </div>
                                            Curation of Biological data is a necessity for scientific
                                            progress as data curation leads to well-documented and
                                            stable resources of Biological information in one place.
                                            Scientific Data Curation is extraction of knowledge from
                                            unstructured data into a structured form. Structured data
                                            sets are most commonly computational forms like databases.
                                            <div />
                                            The Scientific Data Curator or the Data Scientist needs to
                                            extract data (from published patents, experimental data
                                            sets or from unpublished results of data analysis),
                                            understand, process and analyze the extracted data and
                                            then add it to structured datasets in specific formats.
                                            <div />
                                            The Scientific Data Curator or the Data Scientist should
                                            have a thorough understanding of Biology, Molecular
                                            Biology, Genomics and Pharmacology.
                                            <div />
                                            The candidate should be well versed with English, with
                                            good communication and presentation skills.
                                        </div>
                                        <div className="pt-4 mt-1">
                                            <div className="text-black block-sub-title">
                                                Eligibility:
                                            </div>
                                            <ul className="px-3 text-gray">
                                                <li>
                                                    MSc/MS in any branch of Life Sciences (Molecular
                                                    Biology/Microbiology/Biotechnology,
                                                    Pharmacy/Bioinformatics) - Fresher or 0-1 years of
                                                    experience.
                                                </li>
                                                <li>
                                                    PhD in any branch of Life Sciences (Molecular
                                                    Biology/Microbiology/Biotechnology,
                                                    Pharmacy/Bioinformatics) - Fresher
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xl-12 col-lg-12 col-md-12">
                    <div
                        className="accordion accordion-style-01"
                        id="accordion-style-01"
                        data-active-icon="fa-angle-down"
                        data-inactive-icon="fa-angle-right"
                    >
                        <div className="accordion-item bg-white">
                            <div className="accordion-header">
                                <button
                                    data-bs-toggle="collapse"
                                    data-bs-target="#accordion-style-01-02"
                                    aria-expanded="false"
                                    data-bs-parent="#accordion-style-01"
                                    className="w-100 border-0 bg-white d-flex justify-content-between align-items-center px-0"
                                    onClick={() => {
                                        onChangeValue("isOpenSecondDiv", !isOpenSecondDiv);
                                    }}
                                >
                                    <div className="accordion-title position-relative d-flex align-items-center text-dark-gray section-sub-title primary-text mb-0 pe-3 text-start">
                                        Scientific Data Curator or Data Scientist (Chemistry) - job
                                        code - 111003
                                    </div>
                                    <div className="">
                                        {isOpenSecondDiv ? (
                                            <i
                                                className="fa-solid fa-angle-down icon-small"
                                                onClick={() => {
                                                    onChangeValue("isOpenSecondDiv", false);
                                                }}
                                            />
                                        ) : (
                                            <i
                                                className="fa-solid fa-angle-right icon-small"
                                                onClick={() => {
                                                    onChangeValue("isOpenSecondDiv", true);
                                                }}
                                            />
                                        )}
                                    </div>
                                </button>
                            </div>
                            <div
                                id="accordion-style-01-02"
                                className="accordion-collapse collapse"
                                data-bs-parent="#accordion-style-01"
                            >
                                <div className="pe-5">
                                    <div className="row mb-4 pb-1 pt-3">
                                        <div className="col-12">
                                            <div className="position-relative">
                                                <div
                                                    className="w-100 d-block bg-black"
                                                    style={{ height: "1.5px" }}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div ref={elementRefs[1]}>
                                        <div className="text-gray">
                                            <div className="d-block text-dark block-sub-title">
                                                Background:
                                            </div>
                                            All science is strongly dependent on preserving,
                                            maintaining, and adding value to the research record,
                                            including the data, both raw and derived, generated during
                                            the scientific process. Data Curation is extraction of
                                            knowledge from unstructured data into a structured form.
                                            Structured data sets are most commonly computational forms
                                            like databases. We build customized database as a product
                                            and as a service for global biotech and pharmaceutical
                                            firms.
                                            <div className="mb-2 d-block mt-2 text-dark block-sub-title">
                                                Job Description:
                                            </div>
                                            The data Curator needs to extract data (from patents,
                                            published articles), understand, process and analyze the
                                            extracted data and then add it to structured datasets in
                                            specific formats like database.
                                            <div />
                                            Also will be responsible for the development,
                                            implementation and maintenance of various cheminformatics
                                            databases – as an individual project or part of several
                                            projects within the organization.
                                            <div />
                                            <ul className="px-3">
                                                <li>
                                                    The candidate should have a thorough understanding of
                                                    Heterocyclic Chemistry, IUPAC nomenclature and
                                                    stereochemistry.
                                                </li>
                                                <li>
                                                    The candidate must be willing to perform curation,
                                                    annotation, indexing and other production and QA
                                                    processes necessary to harmonise data (from patents,
                                                    published articles).
                                                </li>
                                                <li>
                                                    Additionally, deep understanding, detailed process
                                                    orientation for the extracted data and then add it to
                                                    structured datasets in specific formats.
                                                </li>
                                                <li>
                                                    {" "}
                                                    The candidate should be well versed with English, with
                                                    good communication and presentation skills.
                                                </li>
                                                <li>Strong data analysis skills are desired</li>
                                                <li>
                                                    Excellent interpersonal, organizational, oral/written
                                                    communication and teamwork skills are required.
                                                </li>
                                                <li>
                                                    Candidates must have the ability to work individually
                                                    (independently) as well as within a team.
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="pt-3 text-gray">
                                            <div className="block-sub-title text-black">
                                                Eligibility:
                                            </div>
                                            <ul className="px-3 m-0">
                                                <li>
                                                    MSc in Chemistry: Organic Chemistry / Inorganic
                                                    Chemistry / Analytical Chemistry / Physical Chemistry.
                                                </li>
                                                <li>
                                                    PhD in Chemistry: Organic Chemistry / Inorganic
                                                    Chemistry / Analytical Chemistry / Physical Chemistry.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderLoader = () => {
        return(<DLLoader type={"screen"} isVisible={isShowLoader}/>)
    };

    return (
        <div>
            {renderHeader()}
            {renderTitle()}
            {renderDescriptionUI()}
            {renderFormUI()}
            {renderLoader()}
            <Footer {...props} />
        </div>
    );
}

export default CareersView;
