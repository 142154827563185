import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import Footer from "../../../core/layout/footer/footer";
import EnquireNow from "../../../services/dataCuration/component/enquireNow";
import DataHeaderView from "../dataHeaderView";
import TabUiView from "../tabUiView";
import HeaderImage from "../headerImage";

let s3BaseURLHome = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL + '/Images/home';

const AdmeDatabaseView = (props) => {
    const {isModelOpen, closeModal, openModel, isXSView} = props;
    const parms = useLocation()

    useEffect(() => {
        // setTimeout(() => {
        //     document.querySelector('.fade-in').classList.add('fade-in-delay');
        // }, 1000);
    }, []);

    const renderAdmedatabaseDatabase = () => {
        return (
            <div className=" fade show pt-2" id="tab_four5">
                <div
                    className={(isXSView ? 'mb-4 pb-3 text-start' : '') + " col-md-7 col-lg-4 col-12 pe-md-0 py-md-0 pb-md-0 px-sm-4"}>
                    <img src={s3BaseURLHome + '/adme.png'} alt
                         className="rounded mb-md-4 pb-md-1 mb-sm-4 pb-sm-3 mb-sm-1 data-section-image-container"
                    />
                </div>
                <div className={" col-md-12 col-12 text-start px-sm-4"}>
                    <div
                        className={(isXSView ? 'mb-4 ' : '') + "text-dark p-0 mb-2 mb-lg-3 pb-lg-1 mb-md-4 mb-sm-4 text-title"}>
                        ADME Database
                    </div>
                    <div className='mb-4 px-sm-2 section-text text-start text-gray '>The ADME database contains PK curations for compounds having a
                        varying
                        degree of associated bioactivity metadata in the form of Invitro, Invivo
                        and
                        physicochemical parameters with it, as the case might be, and derived
                        exclusively from English an Asian patents.
                    </div>
                    <button onClick={openModel}
                            className="btn btn-extra-large btn-rounded with-rounded popup-with-move-anim btn-base-color btn-box-shadow text-transform-none position-relative">Explore
                        Now
                        <span className="bg-white text-white rounded-circle px-2 py-2">
                                <i className="fa-solid fa-arrow-right text-dark-gray"></i></span>
                    </button>
                </div>
            </div>

        )
    }


    const renderDataListUI = () => {


        return (
            <div className="bg-white section-divider">
                <div className="page-container">
                    <div className="row ">
                        <div className="">
                            {renderAdmedatabaseDatabase()}
                        </div>
                    </div>
                </div>

            </div>
        )
    };

    const renderUI = () => {
        return (
            <div>
                <HeaderImage
                    {...props}
                    currentDatabase={'ADME'}
                />
                <div className="page-container">
                    <div className='pt-4'>
                        <TabUiView {...props}/>
                    </div>
                </div>
                {renderDataListUI()}
                {isModelOpen &&
                <EnquireNow
                    closeModal={closeModal}
                />
                }
            </div>
        )

    };

    return (
        <div>
            {renderUI()}
            <Footer {...props}/>
        </div>
    );
};

export default AdmeDatabaseView;
