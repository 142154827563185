import React from 'react';
import './aboutStyle.css'
import {motion} from 'framer-motion';
import CountUp from 'react-countup';
import {useInView} from "react-intersection-observer";
import EvolvusAboutImg from '../../contactUs/img/evolvusAboutImg.png'
import appTheme from "../../assets/appTheme";
import Footer from "../../core/layout/footer/footer"

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

function AboutView(props) {
        const {isXSView, isLGView, isMobileView} = props
    const {ref, inView} = useInView({
        threshold: 0.8, // Trigger when 50% of the element is visible
        triggerOnce: true, // Run the animation only once
    });
    const easingFn = (t, b, c, d) => {
        t /= d;
        t--;
        return c * (t * t * t + 1) + b; // Smooth cubic easing
    };

    const renderHeader = () => {
        return (
            <div className="pt-0 cover-background top-space-margin sm-pb-0 mb-3"
                 style={{
                     backgroundImage: `url(${s3BaseURL + '/Images/about/aboutBanner.jpg'})`,
                 }}>
                <div className="page-container">
                    <div className="row align-items-end h-200px sm-h-100px ">
                        <div className="col-lg-9 col-md-6 position-relative text-left page-title-extra-large d-flex justify-content-left align-item-end">
                            <div className="justify-content-left header-img-text slideUpSlowly">
                                <span style={{
                                    fontWeight:' var(--font-style-semibold)'
                                }}>About</span> <b><span
                                style={{color: appTheme.appColor.successLight}}>Us</span></b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderVisionAndMissionUI = () => {
        return (
            <div className='pt-4'>
                <div className="page-container">
                    <div className="row justify-content-center align-items-center mb-6">

                        <div className="col-lg-8 text-start mb-4 mb-lg-0">
                            <div className="text-dark-gray mb-5 mt-3 about-event-subTitle fs-2 ">
                                Vision<span className='theme-font-low'> and </span> Mission
                            </div>
                            <div className='pe-md-5 pe-0'>
                                <div className="w-80 mb-5 pe-md-5 pe-0 section-text text-start text-gray">
                                    Our experience and expertise in the area of Life Sciences arises from
                                    our access to superior technology, scalable infrastructure and access
                                    to talented human capital. We are a professionally managed business house,
                                    with decades of Business experience working with Fortune 500 Companies. Today,
                                    Evolvus has an established practice in Drug discovery and development,
                                    R&D Informatics and Life Science data management. Our products and service
                                    offerings impact a range of life science industries worldwide including
                                    leading pharmaceutical establishments. Evolvus is known globally for its
                                    highly efficient unparalleled solutions and approaches in Discovery informatics,
                                    Clinical and Research Data Management, and as a mainstay for drug discovery
                                    projects.
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-9 text-center text-lg-end position-relative">
                            <img src={EvolvusAboutImg} alt="Vision and Mission" className="img-fluid"
                                 style={{maxHeight: '450px'}}/>
                        </div>
                    </div>
                    {renderCountsUI()}
                </div>
            </div>
        );
    };


    const renderCountsUI = () => {
        return (
            <div
                className="row row-cols-2 row-col-md-2 row-cols-lg-4 justify-content-center ps-3 mt-5 pe-3 theme-color-black mb-5"
                data-anime='{"el": "childs", "translateY": [50, 0], "opacity": [0, 1], "duration": 1200, "delay": 0, "staggervalue": 150, "easing": "easeOutQuad"}'>
                <div ref={ref} className="text-center mb-4 mb-sm-3">
                    <div className="text-dark-gray mb-0 ls-minus-3px position-relative about-count z-index-0">
                        {inView ? (
                            <CountUp end={24} separator="" duration={1.5} easingFn={easingFn}/>
                        ) : (
                            24
                        )}
                        <div className="position-absolute bottom-0 start-0 w-100">
                            <div className="bg-gradient-flamingo-amethyst-green h-1 opacity-2"></div>
                        </div>
                    </div>
                    <div className="d-block fs-md-5 about-us-counts-title">Years in business</div>
                </div>
                <div className="col text-center mb-4 mb-sm-3">
                    <div
                        className="text-dark-gray mb-0 ls-minus-3px position-relative about-count theme-font-bolder theme-font-section-title z-index-0">
                        {inView ? <CountUp end={15} separator="" duration={1.5}/> : 15}
                    </div>
                    <div className="d-block about-us-counts-title">Databases</div>
                </div>
                <div className="col text-center mb-4 mb-sm-3">
                    <div
                        className="text-dark-gray mb-0 ls-minus-3px position-relative about-count theme-font-bolder theme-font-section-title z-index-0">
                        {inView ? <CountUp end={56} separator="" duration={1.5}/> : 56}
                    </div>
                    <div className="d-block about-us-counts-title">Custom solutions</div>
                </div>
                <div className="col text-center mb-4 mb-sm-3">
                    <div
                        className="text-dark-gray fw-bold mb-0 ls-minus-3px position-relative about-count theme-font-bolder theme-font-section-title z-index-0">
                        {inView ? <CountUp end={36} separator="" duration={1.5}/> : 36}
                    </div>
                    <div className="d-block about-us-counts-title">Happy clients</div>
                </div>
            </div>
        )
    };

    const renderProcessUI = () => {
        return (
            <div className=" pt-0 position-relative"
                 style={{
                     backgroundColor: 'var(--gray-light)'
                 }}>
                <div className="page-container">
                    <div>
                        <div className="py-1 justify-content-start mb-5">
                            <motion.div
                                initial={{animationPlayState: 'paused'}}
                                whileInView={{animationPlayState: 'running'}}
                                viewport={{once: true, amount: 0.1}}
                                className="text-start mt-5 slideUpSlowly"
                                style={{animationDelay: '300ms'}}
                            >
                                <div
                                    className="alt-font  theme-color-black ls-minus-2px theme-font-low sm-ls-minus-1px fs-2">
                                    The <span className={'theme-font-bolder'}>creative process</span> behind our projects
                                </div>
                            </motion.div>
                        </div>
                        <div className={isMobileView ? 'pb-2' :"row row-cols-1 row-cols-lg-3 row-cols-md-2 justify-content-center"}>
                            <div className="col mb-4">
                                <motion.div
                                    initial={{animationPlayState: 'paused'}}
                                    whileInView={{animationPlayState: 'running'}}
                                    viewport={{once: true, amount: 0.1}}
                                    className="px-4 py-1 border-radius-3 slideUpSlowly"
                                    style={{animationDelay: '500ms', backgroundColor: 'var(--gray-light)'}}
                                >
                                    {isMobileView ? <div className="mb-3 d-flex justify-content-center">
                                            <img src={s3BaseURL + '/Icons/discussion.png'}
                                                 style={{height: '100px'}} alt=""/>
                                        </div> :
                                        <div className=" mb-4 d-flex justify-content-center">
                                            <img src={s3BaseURL + '/Icons/discussion.png'}
                                                 style={{height: '100px'}} alt=""/>
                                        </div>
                                    }
                                    <div className={isMobileView ? "text-center" : "'"}>
                                    <span className="d-inline-block ms-sm-0 ps-sm-0 section-sub-title">
                                        Discussion of the Idea
                                    </span>
                                        <div className="d-flex align-items-start">
                                            {!isMobileView && <div className="fw-bold p-3 rounded-circle theme-font-bolder theme-color-black theme-circle
                                        d-flex justify-content-center align-items-center mt-1">
                                                1
                                            </div>}
                                            <div className={isMobileView ? "mb-2 text-center w-100" : "mb-2"}>
                                                To architect the right solution pertaining the context
                                            </div>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>

                            <div className="col mb-4">
                                <motion.div
                                    initial={{animationPlayState: 'paused'}}
                                    whileInView={{animationPlayState: 'running'}}
                                    viewport={{once: true, amount: 0.1}}
                                    className="px-4 py-1 border-radius-3 slideUpSlowly"
                                    style={{animationDelay: '500ms', backgroundColor: 'var(--gray-light)'}}
                                >
                                    {isMobileView ? <div className="mb-3 d-flex justify-content-center">
                                            <img src={s3BaseURL + '/Icons/handCrafted.png'} style={{height: '100px'}} alt=""/>
                                        </div> :
                                        <div className=" mb-4 d-flex justify-content-center">
                                            <img src={s3BaseURL + '/Icons/handCrafted.png'}
                                                 style={{height: '100px'}} alt=""/>
                                        </div>
                                    }
                                    <div className={isMobileView ? "text-center" : "'"}>
                                    <span className="d-inline-block ms-sm-0 ps-sm-0 section-sub-title">
                                        Handcrafted Solutions
                                    </span>
                                        <div className="d-flex align-items-start">
                                            {!isMobileView && <div className="fw-bold px-3 rounded-circle theme-font-bolder theme-color-black theme-circle
                                        d-flex justify-content-center align-items-center mt-1">
                                                2
                                            </div>}
                                            <div className={isMobileView ? "mb-2 text-center  w-100" : "mb-2"}>
                                                A testimony for humanity and intellect. To strive.
                                            </div>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                            {/* Handcrafted Solutions */}

                            <div className="col mb-4">
                                <motion.div
                                    initial={{animationPlayState: 'paused'}}
                                    whileInView={{animationPlayState: 'running'}}
                                    viewport={{once: true, amount: 0.1}}
                                    className="px-4 py-1 border-radius-3 slideUpSlowly"
                                    style={{animationDelay: '500ms', backgroundColor: 'var(--gray-light)'}}
                                >
                                    {isMobileView ? <div className="mb-3 d-flex justify-content-center">
                                            <img src={s3BaseURL + '/Icons/completed.png'} style={{height: '100px'}} alt=""/>
                                        </div> :
                                        <div className=" mb-4 d-flex justify-content-center">
                                            <img src={s3BaseURL + '/Icons/completed.png'}
                                                 style={{height: '100px'}} alt=""/>
                                        </div>
                                    }
                                    <div className={isMobileView ? "text-center" : "'"}>
                                    <span className="d-inline-block ms-sm-0 ps-sm-0 section-sub-title">
                                        Completed Project
                                    </span>
                                        <div className="d-flex align-items-start">
                                            {!isMobileView && <div className="fw-bold px-3 rounded-circle theme-font-bolder theme-color-black theme-circle
                                        d-flex justify-content-center align-items-center mt-1">
                                                3
                                            </div>}
                                            <div className={isMobileView ? "mb-2 text-center  w-100" : "mb-2"}>
                                                Actually, its iterative, since we’re trendsetters and unique.
                                            </div>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                            {/* Completed Project */}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div>
            {renderHeader()}
            {renderVisionAndMissionUI()}
            {renderProcessUI()}
            <Footer {...props}/>
        </div>
    );
}

export default AboutView;