import React, {useEffect, useRef, useState} from 'react';
import './home.css'

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL+'/Images/home';

const OurProductsView = (props) => {
    useEffect(()=>{
        setTimeout(() => {
            document.querySelector('.fade-in').classList.add('fade-in-delay');
        }, 1000);
    }, []);

    const renderOurPoductsUI = () => {
        const{isMobileView}= props;

        return (
            <div>
                <div className="page-container section-divider">
                    <div className={isMobileView ? "" : "d-flex align-items-center"}>
                        <div style={{flex:4}} className="about-count">Our Products</div>
                        <div style={{flex:6}}>
                            <div className=" section-text text-black">
                                Evolvus has developed a substantial life sciences knowledge base, which we draw upon, in
                                tandem with our bespoke data curation, to rapidly develop customer-specific data
                                solutions.
                                Some of our knowledge assets are listed here.
                            </div>
                        </div>
                        <div  style={{flex:2}}/>
                    </div>
                </div>
                {renderCardsUI()}
            </div>
        )
    };


    const renderCardsUI = () => {
        const {isSMView, isXSView} = props;

        return (
            <div className="page-container fade-in">
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 justify-content-center">
                    <div className={isXSView ? "px-2 mb-5" : "px-4 mb-5"}>
                        <div className={isSMView ? "px-5" :"mx-3"}>
                            <div className={`card-box-shadow text-center transition-inner-all d-flex align-items-center h-100`}
                            >
                                <div className="card-padding cursor"
                                     onClick={()=> props.history.push("/data/liceptor-database")}>
                                    <div className='mb-4'>
                                        <img src={s3BaseURL+ '/Liceptor.png'}
                                             alt="Liceptor Logo"
                                             className="mb-3 image-tag" />
                                    </div>
                                    <div className="block-sub-title">Small molecule</div>
                                    <div className="block-sub-title">bioactivity Database</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={isXSView ? "px-2 mb-5" : "px-4 mb-5"}>
                        <div className={isSMView ? "px-5" :"mx-3"}>
                            <div className={`card-box-shadow text-center d-flex align-items-center h-100  `}
                            >
                                <div className="card-padding cursor"
                                     onClick={()=> props.history.push("/data/oncogen-database")}>
                                    <div className='mb-4'>
                                        <img src={s3BaseURL+ '/Oncogen.png'}
                                             alt="Oncogen Logo"
                                             className="mb-3 image-tag" />
                                    </div>
                                    <div className="block-sub-title">Cancer genomics curated</div>
                                    <div className="block-sub-title">knowledgebase</div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={isXSView ? "px-2 mb-5" : "px-4 mb-5"}>
                        <div className={isSMView ? "px-5" :"mx-3"}>
                            <div className={`card-box-shadow text-center d-flex align-items-center h-100`}
                            >
                                <div className="card-padding cursor"
                                     onClick={()=> props.history.push("/data/theme-database")}>
                                    <div className='mb-4'>
                                        <img src={s3BaseURL+ '/Theme.png'}
                                             alt="Theme Logo"
                                             className="mb-3 image-tag"
                                        />
                                    </div>
                                    <div className="block-sub-title">Biotransformation</div>
                                    <div className="block-sub-title">reactions DB</div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={isXSView ? "px-2 mb-5" : "px-4 mb-5"}>
                        <div className={isSMView ? "px-5" :"mx-3"}>
                            <div className={`card-box-shadow text-center d-flex align-items-center h-100 `}
                            >
                                <div className="card-padding cursor"
                                     onClick={()=> props.history.push("/data/proximers-database")}>
                                    <div className='mb-4'>
                                        <img src={s3BaseURL+ '/Proximers.png'}
                                             alt="Proximers Logo"
                                             className="mb-3 image-tag"
                                        />
                                    </div>
                                    <div className="block-sub-title">Protein degraders, MG</div>
                                    <div className="block-sub-title">BMC datasets</div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={isXSView ? "px-2 mb-5" : "px-4 mb-5"}>
                        <div className={isSMView ? "px-5" :"mx-3"}>
                            <div className={`card-box-shadow text-center d-flex align-items-center h-100`}
                            >
                                <div className="card-padding cursor"
                                     onClick={()=> props.history.push("/data/adme-database")}>
                                    <div className='mb-4'>
                                        <img src={s3BaseURL+ '/adme.png'}
                                             alt="ADME Logo"
                                             className="mb-3 image-tag"
                                        />
                                    </div>
                                    <div className="block-sub-title">Curated PK endpoints</div>
                                    <div className="block-sub-title"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={isXSView ? "px-2 mb-5" : "px-4 mb-5"}>
                        <div className={isSMView ? "px-5" :"mx-3"}>
                            <div className={`card-box-shadow text-center d-flex align-items-center h-100  `}
                                 style={{
                                     maxHeight: 270,
                                 }}>
                                <div className="card-padding cursor">
                                    <img src={s3BaseURL+ '/ConnectorBase.png'}
                                         alt="Connector Base Logo"
                                         className="mb-3 image-tag"
                                    />
                                </div>
                                <div className="block-sub-title"/>
                                <div className="block-sub-title"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div>
            {renderOurPoductsUI()}
        </div>
    );
};

export default OurProductsView;