import * as log from "loglevel";
import {isEmpty} from "./validations";
import store from "../store";
import {showSystemErrorBlock, showToastMessage} from "../core/actions/appActions";
import Helmet from 'react-helmet';
import React from "react";
const API_URL = process.env.REACT_APP_API_URL;

let controller = new AbortController();
let signal = controller.signal;


export function getAuthorizationToken() {
    let token = null;
    try {
        let fromStorage = '';
        if (localStorage.hasOwnProperty('jwtToken')) {
            token = localStorage.getItem('jwtToken');
            fromStorage = 'localStorage';
        } else if (sessionStorage.hasOwnProperty('jwtSessionToken')) {
            token = sessionStorage.getItem('jwtSessionToken');
            fromStorage = 'sessionStorage';
        } else {
            token = null;
        }
        if (token) {
            return {
                status: true,
                token: token,
                fromStorage: fromStorage
            };
        } else {
            return {
                status: false,
                err: 'no token available',
            };
        }
    } catch (err) {
        return {
            status: false,
            err: err,
        };
    }
}

export const httpGet = (url, accountId, handleErrorInComponent) => {
    let statusCode;
    let headers;
    if (isEmpty(accountId)) {
        headers = {
            'Accept': 'application/json',
            ...getAuthorization(),
        }
    } else {
        headers = {
            'Accept': 'application/json',
            'x-account-id': accountId,
            ...getAuthorization(),
        }
    }
    return fetch(API_URL + url, {
        method: 'GET',
        headers: headers,
    })
        .then(response => {
            // capture the status code if the response to json conversion call breaks
            statusCode = response.status;

            if (response.status === 204) {
                return response;
            } else {
                return response.json();
            }

        })
        .then(data => {

            if (statusCode === 200 || statusCode === 201) {
                if (Array.isArray(data)) {
                    return {data: data, statusCode: statusCode, success: true}
                }
                if (typeof data === 'object') {
                    return {data: data, statusCode: statusCode, success: true}
                }
                return {data: data, statusCode: statusCode, success: true}
            }

            if (statusCode === 204) {
                return {data: null, statusCode: statusCode, success: true}
            }

            if (statusCode >= 400 && statusCode < 500) {
                if (statusCode === 401) {
                    // store.dispatch(userLoggedOut())
                } else {
                    if (handleErrorInComponent){

                    }else {
                        if (statusCode === 403) {
                            // store.dispatch(setNotAccessible())
                        } else {
                            store.dispatch(showToastMessage('warning', data.__error))
                        }
                    }
                }
                return {...data, statusCode: statusCode, success: false}
            }

            if (statusCode >= 500) {
                store.dispatch(showSystemErrorBlock());
                return {data: null, statusCode: statusCode, success: false}
            }
        })
        .catch((error) => {
            logAPIError({statusCode: statusCode, error: error, api: url, apiMethod: 'GET'});
            store.dispatch(showSystemErrorBlock());
            return {statusCode: statusCode, error: error, success: false}
        });
};

export const httpPost = (url, data, accountId, handleErrorInComponent) => {
    let statusCode;
    let headers;
    if (isEmpty(accountId)) {
        headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...getAuthorization(),
        }
    } else {
        headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-account-id': accountId,
            ...getAuthorization(),
        }
    }

    return fetch(API_URL + url, {
        method: 'POST',
        headers: headers,
        body: isEmpty(data) ? '' : JSON.stringify(data),
    })
        .then(response => {
            // capture the status code if the response to json conversion call breaks
            statusCode = response.status;
            if (response.status === 204) {
                return response
            } else {
                return response.json()
            }
        })
        .then(data => {
            if (statusCode === 200 || statusCode === 201) {
                if (Array.isArray(data)) {
                    return {data: data, statusCode: statusCode, success: true}
                }
                if (typeof data === 'object') {
                    return {data: data, statusCode: statusCode, success: true}
                }
                return {data: data, statusCode: statusCode, success: true}
            }

            if (statusCode === 204) {
                return {data: null, statusCode: statusCode, success: true}
            }

            if (statusCode >= 400 && statusCode < 500) {
                if (statusCode === 401) {
                    // store.dispatch(userLoggedOut())
                } else {
                    if (handleErrorInComponent){

                    }else {
                        if (statusCode === 403) {
                            // store.dispatch(setNotAccessible())
                        } else {
                            store.dispatch(showToastMessage('warning', data.__error))
                        }
                    }
                }
                return {...data, statusCode: statusCode, success: false}
            }

            if (statusCode >= 500) {
                store.dispatch(showSystemErrorBlock());
                return {data: null, statusCode: statusCode, success: false}
            }
        })
        .catch((error) => {
            logAPIError({statusCode: statusCode, error: error, api: url, payload: data, apiMethod: 'POST'});
            controller = new AbortController();  // reset abort controller to continue further requests
            setSignal();
            store.dispatch(showSystemErrorBlock());
            return {statusCode: statusCode, error: error, success: false}
        });
};

export const httpPut = (url, data, accountId) => {
    let statusCode;
    return fetch(API_URL + url, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-account-id': accountId,
            ...getAuthorization(),
        },
        body: JSON.stringify(data),
    })
        .then(response => {
            statusCode = response.status;
            // return response
            // return response.json()

            if (response.status === 204) {
                return response
            } else {
                return response.json()
            }
        })
        .then(data => {

            if (statusCode === 200 || statusCode === 201) {
                if (Array.isArray(data)) {
                    return {data: data, statusCode: statusCode, success: true}
                }
                if (typeof data === 'object') {
                    return {data: data, statusCode: statusCode, success: true}
                }
                return {data: data, statusCode: statusCode, success: true}
            }

            if (statusCode === 204) {
                return {data: null, statusCode: statusCode, success: true}
            }

            if (statusCode >= 400 && statusCode < 500) {
                if (statusCode === 401) {
                    // store.dispatch(userLoggedOut())
                } else {
                    if (statusCode === 403) {
                        // store.dispatch(setNotAccessible())
                    } else {
                        store.dispatch(showToastMessage('warning', data.__error))
                    }
                }
                return {...data, statusCode: statusCode, success: false}
            }

            if (statusCode >= 500) {
                store.dispatch(showSystemErrorBlock());
                return {data: null, statusCode: statusCode, success: false}
            }
        })
        .catch((error) => {
            logAPIError({statusCode: statusCode, error: error, api: url, payload: data, apiMethod: 'PUT'});
            store.dispatch(showSystemErrorBlock());
            return {statusCode: statusCode, error: error, success: false}
        });
};

export const httpDelete = async (url, data, accountId) => {

    let statusCode, headers;
    if (isEmpty(accountId)) {
        headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...getAuthorization(),
        }
    } else {
        headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-account-id': accountId,
            ...getAuthorization(),
        }
    }

    return fetch(API_URL + url, {
        method: 'DELETE',
        headers: headers,

    })
        .then(response => {
            // capture the status code if the response to json conversion call breaks
            statusCode = response.status;

            if (response.status === 204) {
                return response
            } else {
                return response.json()
            }
        })
        .then(data => {
            if (statusCode === 200 || statusCode === 201) {
                if (Array.isArray(data)) {
                    return {data: data, statusCode: statusCode, success: true}
                }
                if (typeof data === 'object') {
                    return {data: data, statusCode: statusCode, success: true}
                }
                return {data: data, statusCode: statusCode, success: true}
            }

            if (statusCode === 204) {
                return {data: null, statusCode: statusCode, success: true}
            }

            if (statusCode >= 400 && statusCode < 500) {
                if (statusCode === 401) {
                    // store.dispatch(userLoggedOut())
                } else {
                    if (statusCode === 403) {
                        // store.dispatch(setNotAccessible())
                    } else {
                        store.dispatch(showToastMessage('warning', data.__error))
                    }
                }
                return {...data, statusCode: statusCode, success: false}
            }

            if (statusCode >= 500) {
                store.dispatch(showSystemErrorBlock());
                return {data: null, statusCode: statusCode, success: false}
            }
        })
        .catch((error) => {
            logAPIError({statusCode: statusCode, error: error, api: url, payload: data, apiMethod: 'DELETE'});
            store.dispatch(showSystemErrorBlock());
            return {statusCode: statusCode, error: error, success: false}
        });

};

export const getAppEnvironmentVariables = () => {
    return process.env;
};

export const getAppPlatform = () => {
    return 'WEB';
};

export const getAuthorization = () => {
    if (getAuthorizationToken().token) {
        return {
            'Authorization': 'Bearer ' + getAuthorizationToken().token,
        }
    }
    return {}
};

export const logAPIError = (errorData) => {
    // add the api status codes for which error logging is not required
    if (errorData?.statusCode === 409) {
    } else {
        log.error(errorData);
    }

};


export const abortWebFetch = () => {
    controller.abort();
    setSignal();
};

export const setSignal = () => {
    signal = controller.signal;
};

export const contentTypeName = (contentType) => {
    switch (contentType) {
        case 'CUSTOMER':
            return 'All Customers';
        case 'LEAD':
            return 'All Leads';
        case 'PROSPECTS':
            return 'All Prospects';
    }
};
export const contentType = (contentType) => {
    switch (contentType) {
        case 'CUSTOMER':
            return 'Customers';
        case 'LEAD':
            return 'Leads';
        case 'PROSPECTS':
            return 'Prospects';
    }
};


export const getMetaTagUi = (pathname, seoMetaData, isAuthenticated) => {

    let genericMetaTag = (
        <Helmet>
            {
                (process.env.REACT_APP_ENVIRONMENT === 'QA') &&
                <title>QA-Evolvus</title>
            }
            {
                (process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT') && <title>Evolvus</title>
            }
            {
                (process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION') &&
                <title>Evolvus</title>
            }
            <meta name="description"
                  content='SCuiDo App'/>
            <meta name="keywords" content="vegan, plant based"/>

        </Helmet>
    );

    if (isAuthenticated) {
        return genericMetaTag;
    }

    if (pathname === '' || pathname === '/' || pathname === '/home') {
        pathname = '/'
    }

    if (isEmpty(seoMetaData)) {

        if (pathname === '/') {
            return genericMetaTag;
        }

        return;

    }
    let data = {};

    if (pathname === '/'){
        data = seoMetaData.find(item => {
            return (item.url === API_URL + pathname || item.url === API_URL)
        });
    }else {
        data = seoMetaData.find(item => {
            return item.url.includes(pathname)
        });
    }

    if (isEmpty(data)) {
        return genericMetaTag
    }

    let prefix = "";

    if (process.env.REACT_APP_ENVIRONMENT === 'QA') {
        prefix = "QA ";
    }

    if (process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT') {
        prefix = "DEV ";
    }


    let SEOAttributes=data?.SEOAttributes;
    let SEONameAttributes=[];
    let SEOPropertyAttributes=[];

    SEOAttributes.map((obj)=>{
        if(obj.SEOMasterType==='name'){
            SEONameAttributes.push(obj);
        }else if(obj.SEOMasterType==='property'){
            SEOPropertyAttributes.push(obj);
        }
    })

    return (
        <Helmet>
            <title>{`` + prefix + data?.title}</title>
            {
                SEONameAttributes.map((obj)=>{
                    return(
                        <meta name={obj?.SEOMasterKey} content={obj?.value} />
                    );
                })
            }
            {
                SEOPropertyAttributes.map((obj)=>{
                    return(
                        <meta property={obj?.SEOMasterKey} content={obj?.value} />
                    );
                })
            }
        </Helmet>
    );

};
