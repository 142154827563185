import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import Footer from "../../../core/layout/footer/footer";
import appTheme from "../../../assets/appTheme";
import EnquireNow from "../../../services/dataCuration/component/enquireNow";
import DataHeaderView from "../dataHeaderView";
import TabUiView from "../tabUiView";
import HeaderImage from "../headerImage";


let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL + '/Images/data';
let s3BaseURLHome = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL + '/Images/home';

const OncogenDatabaseView = (props) => {
    const {isModelOpen, closeModal, openModel, isXSView} = props;
    const parms = useLocation()

    useEffect(() => {
        // setTimeout(() => {
        //     document.querySelector('.fade-in').classList.add('fade-in-delay');
        // }, 1000);
    }, []);

    const renderOncogenDatabase = () => {
        return (
            <div className="fade show pt-2" id="">
                <div
                    className={(isXSView ? 'mb-4 pb-3 text-start' : '') + " col-md-7 col-lg-4 col-12 pe-md-0 py-md-0 pb-md-0 px-sm-4"}>
                    <img src={s3BaseURLHome + '/Oncogen.png'} alt
                         className="rounded mb-md-4 pb-md-1 mb-sm-4 pb-sm-3 mb-sm-1 data-section-image-container"
                    />
                </div>
                <div className={" col-md-12 col-12 text-start px-sm-4"}>
                    <div
                        className={(isXSView ? 'mb-4 ' : '') + "text-dark p-0 mb-2 mb-lg-3 pb-lg-1 mb-md-4 mb-sm-4 text-title"}>Oncogen
                        Database
                    </div>
                    <div className='mb-4 section-text text-start text-gray  '>
                        <div className='d-inline mb-3'>OncoGen - Oncology Biomarker Database</div>
                        <div/>

                        <div className='mb-3'>
                            Biomarkers have emerged among the forefront in enabling the drug discovery process. Our
                            biomarkers database brings a new dimension to drugs, targets and genes from a diverse range
                            of sources.
                        </div>

                        <div className='mb-3'>
                            We offer unique biomarker database combined with related drugs, targets and genes to give
                            researchers a powerful tool to make informed decisions as you strive to improve cost
                            efficiency, predict and minimise risk and avoid late stage attrition.
                        </div>
                        <div className='mb-3'>
                            OncoGen is our Oncology Knowledgebase, and is the definitive resource for oncology
                            focused physicians, researchers, clinicians for their genomic and clinical data
                            requirements. It is a comprehensive oncology knowledgebase suite for the interpretation of
                            complex genomic profiles for clinical researchers and oncologists.
                        </div>
                        <div className='mb-3'>
                            Rapid generation of high volumes of unstructured data in the genomic and clinical oncology
                            domain poses a problem for scientists in analysing genomic information. We solve this
                            problem by providing data in a structured format, relying on our expertise in manual
                            scientific data curation and cutting-edge text mining approaches.
                        </div>
                        <div className='mb-3'>
                            The Oncology Knowledgebase comprises of three modules which provide complex correlations
                            between genomic variants, oncology drugs, cancer types and clinical trials:
                        </div>
                        <div className='section-sub-title'>
                            Molecular alterations Module
                        </div>
                        <div className='mb-3'>
                            The Genomic molecular alteration Module comprises expert curated data from various sources,
                            to provide complex correlations between genomic alterations, cancer types, clinical
                            endpoints and therapies.
                        </div>

                        <div className='section-sub-title'>
                            IAT Module
                        </div>

                        <div className='mb-3'>
                            The Investigational and approved therapies Module comprises curated information for Approved
                            and Investigational Oncology Drugs and covers Phases of Drug Development, Molecular Targets,
                            Mechanism of Action and Marketing Approval Information.
                        </div>


                        <div className='section-sub-title'>
                        Clinical Trial Module
                        </div>
                        <div className='mb-3'>
                            The Clinical Trial Module comprises curated data for ongoing cancer clinical trials from
                            global clinical trial registries. It helps to find relevant clinical trials for cancer
                            patients, based on their genomic profile and cancer type.
                        </div>

                        <div className='text-header'>
                        Our Oncology Database features:
                        </div>
                        <ul style={{paddingLeft: 11}}>
                            <li>Extensively Indexed and Expert Curated Genomic and Clinical Oncology Data</li>
                            <li>Use of standard ontologies and vocabularies</li>
                            <li>Personalised and Flexible Licensing Options</li>
                            <li>Periodically Updated to reflect Latest Information</li>
                            <li>Can be Integrated into your Analysis Pipeline for Variant Interpretation and
                                Reporting
                            </li>
                        </ul>
                    </div>
                    <button onClick={openModel}
                            className="btn btn-extra-large btn-rounded with-rounded popup-with-move-anim btn-base-color btn-box-shadow text-transform-none position-relative">Explore
                        Now
                        <span className="bg-white text-white rounded-circle px-2 py-2">
                                <i className="fa-solid fa-arrow-right text-dark-gray"></i></span>
                    </button>
                </div>
            </div>

        )
    }

    const renderDataListUI = () => {


        return (
            <div className="bg-white section-divider">
                <div className="page-container">
                    <div className="row ">
                        <div className="">
                            {renderOncogenDatabase()}
                        </div>
                    </div>
                </div>

            </div>
        )
    };

    const renderUI = () => {
        return (
            <div>
                <HeaderImage
                    {...props}
                    currentDatabase={'Oncogen'}
                />
                <div className="page-container">
                    <div className='pt-4'>
                        <TabUiView {...props}/>
                    </div>
                </div>
                {renderDataListUI()}
                {isModelOpen &&
                <EnquireNow
                    closeModal={closeModal}
                />
                }
            </div>
        )

    };

    return (
        <div>
            {renderUI()}
            <Footer {...props}/>
        </div>
    );
};

export default OncogenDatabaseView;
