import React, {useEffect, useRef, useState} from 'react';
let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL + '/Images';
import './dataCurationComponent.css'
const DataCurationComponent = (props) => {

    const [isVisible, setIsVisible] = useState([false, false, false]);

    // Create refs for multiple elements
    const elementRefs = [useRef(null), useRef(null), useRef(null)];


    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                const index = elementRefs.findIndex(ref => ref.current === entry.target);
                if (index !== -1 && !isVisible[index]) { // Only update visibility if it's not already true
                    // Update visibility state for the observed element
                    setIsVisible((prev) => {
                        const newVisibility = [...prev];
                        newVisibility[index] = entry.isIntersecting; // Set visibility status for the element
                        return newVisibility;
                    });
                }
            },
            { threshold: 0.3 } // Trigger when 30% of the element is visible
        );

        // Start observing each element referenced in `elementRefs`
        elementRefs.forEach(ref => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        // Clean up observer when component is unmounted
        return () => {
            elementRefs.forEach(ref => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            });
        };
    }, [isVisible]);


    const renderDataCurationUI = () => {
        return (
            <div className="row pt-0 sm-pt-0 ">
                <div className="col-lg-6 md-mb-20px sm-mb-30px appear">
                <span
                    className={`alt-font fs-70 lg-fs-80 md-fs-70 fw-600 text-Green ${isVisible[0]? 'fade-out':'' } `} ref={elementRefs[0]} >
                    <span className="text-highlight text-Green">Data Curation

                        <span className={`bg-green h-5px bottom-10px  ${isVisible[0]?'underline-separator':'hidden'}`}>
                    </span>
                    </span>
                </span>
                    <p className={`mb-30px w-75 lg-w-100 pt-4  ${isVisible[0]? 'ls-minus-1px slideUp slideUpSlowly':'hidden'}`}>We are excited for our work and how it
                        positively impacts
                        clients. With over 12 years of experience we have been constantly providing excellent web
                        solutions which is best in-class experience for our clients.</p>
                    <div className="row slideUpSlowly">
                        <div className="col-xl-4 col-md-6">
                            <ul className="p-0 m-0 list-style-02">
                                <li><i className="fa-solid fa-plus fs-12 text-green me-10px"/><span
                                    className="text-green">Chemistry</span></li>
                                <li><i className="fa-solid fa-plus fs-12 text-green me-10px"/><span
                                    className="text-green">Biology</span></li>
                                <li><i className="fa-solid fa-plus fs-12 text-green me-10px"/><span
                                    className="text-green">Clinical</span></li>
                            </ul>
                        </div>
                        <div className="col-xl-5 col-md-6">
                            <ul className="p-0 m-0 list-style-02">
                                <li><i className="fa-solid fa-plus fs-12 text-green me-10px"></i><span
                                    className="text-green">Research </span></li>
                                <li><i className="fa-solid fa-plus fs-12 text-green me-10px"></i><span
                                    className="text-green">BioInformatics</span></li>
                                <li><i className="fa-solid fa-plus fs-12 text-green me-10px"></i><span
                                    className="text-green">ChemInformatics</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-xl-5 col-lg-6 offset-xl-1 scroll-animation"  >
                    <img src={s3BaseURL + '/data/dataCuration.jpg'}

                         height={450}
                         width={450}
                         className="" alt/>
                </div>

            </div>
        )
    };

    const renderDevelopmentUI = () => {



        return (
            <div className="row pt-15 flex-md-row-reverse">
                <div
                    className="col-xl-6 col-lg-6 offset-xl-1 md-mb-50px sm-mb-30px appear anime-child anime-complete " ref={elementRefs[1]}>

                    <span
                        className={`alt-font fs-70 lg-fs-80 md-fs-70 fw-600 text-Green   ${isVisible[1]?'fade-out ':'hidden'} `}
                       >
                       <span
                        className="text-highlight text-Green">Development<span
                        className={`bg-green h-5px bottom-10px  ${isVisible[1]?'underline-separator2':'hidden'}`}></span></span></span>
                    <p className={`mb-30px w-75 lg-w-100 pt-4  ${isVisible[1]? 'ls-minus-1px  slideUp slideUpSlowly':'hidden'}`}>We are excited for our work and how it
                        positively impacts
                        clients. With
                        over 12 years of experience we have been constantly providing excellent web solutions
                        which is best
                        in-className experience for our clients.</p>
                    <div className="row slideUpSlowly">
                        <div className="col-xl-4 col-md-6">
                            <ul className="p-0 m-0 list-style-02">
                                <li><i className="fa-solid fa-plus fs-12 text-white me-10px"/><span
                                    className="text-white">API development</span>
                                </li>
                                <li><i className="fa-solid fa-plus fs-12 text-white me-10px"/><span
                                    className="text-white">Cloud migration</span>
                                </li>
                                <li><i className="fa-solid fa-plus fs-12 text-white me-10px"/><span
                                    className="text-white">JavaScript</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-xl-5 col-md-6">
                            <ul className="p-0 m-0 list-style-02">
                                <li><i className="fa-solid fa-plus fs-12 text-white me-10px"/><span
                                    className="text-white">WordPress</span>
                                </li>
                                <li><i className="fa-solid fa-plus fs-12 text-white me-10px"/><span
                                    className="text-white">Custom website</span>
                                </li>
                                <li><i className="fa-solid fa-plus fs-12 text-white me-10px"/><span
                                    className="text-white">Flutter framework</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        )
    };

    const renderCaseStudyUI = () => {



        return (
            <div className="row pt-15 pb-5 ">
                <div
                    className="col-xl-6 col-lg-6 offset-xl-1 md-mb-50px sm-mb-30px appear anime-child anime-complete " ref={elementRefs[2]}>
                    <span
                        className={`alt-font fs-70 lg-fs-80 md-fs-70 fw-600 text-Green ${isVisible[2]?'fade-out ':'hidden'} `}
                        data-shadow-animation="true" data-animation-delay="500"> <span
                        className="text-highlight text-Green">Case Study<span
                        className={`bg-green h-5px bottom-10px  ${isVisible[2]?'underline-separator':'hidden'}`}></span></span></span>
                    <p className={`mb-30px w-75 lg-w-100 pt-4 ${isVisible[2]? '  slideUp slideUpSlowly':'hidden'}`}>We are excited for our work and how it
                        positively impacts
                        clients. With
                        over 12 years of experience we have been constantly providing excellent web solutions
                        which is best
                        in-className experience for our clients.</p>
                    <div className="row slideUpSlowly">
                        <div className="col-xl-4 col-md-6">
                            <ul className="p-0 m-0 list-style-02">
                                <li><i className="fa-solid fa-plus fs-12 text-white me-10px"/><span
                                    className="text-white">Shipping features</span>
                                </li>
                                <li><i className="fa-solid fa-plus fs-12 text-white me-10px"/><span
                                    className="text-white">Payment gateways</span>
                                </li>
                                <li><i className="fa-solid fa-plus fs-12 text-white me-10px"/><span
                                    className="text-white">Categorization</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-xl-5 col-md-6">
                            <ul className="p-0 m-0 list-style-02">
                                <li><i className="fa-solid fa-plus fs-12 text-white me-10px"/><span
                                    className="text-white">CMS solutions</span>
                                </li>
                                <li><i className="fa-solid fa-plus fs-12 text-white me-10px"/><span
                                    className="text-white">eCommerce platform</span>
                                </li>
                                <li><i className="fa-solid fa-plus fs-12 text-white me-10px"/><span
                                    className="text-white">Apps development</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        )
    };

    return (
        <div>
            {renderDataCurationUI()}
            {renderDevelopmentUI()}
            {renderCaseStudyUI()}
        </div>
    );
};

export default DataCurationComponent;