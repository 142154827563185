import React from 'react';
import AboutView from "./aboutView";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

function About(props) {
    return (
        <AboutView {...props}/>
    );
}
const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    isLGView: state.appState.deviceInfo.isLGView,
});

export default connect(mapStateToProps, {})((About));