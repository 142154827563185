import React from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import DiscoveryPlatformsView from "./DiscoveryPlatformsView";


const DiscoveryPlatforms = (props) => {

    return (
        <DiscoveryPlatformsView
            {...props}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(withTranslation('translations')(DiscoveryPlatforms));
