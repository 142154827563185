const appTheme = {
    appColor: {
        primary: '#282828',
        secondary: '#FF9800',
        tertiary: '#FFF6E8',
        success: '#029C48',
        successLight: '#72bf44',
        danger: '#D72F2F',
        dangerLight: '#FFEBEB',
        blueDark: '#4F46E5',
        blueLight: '#FAFAFA',
        grayLight: '#F1F5F9',
        grayDark: '#666666',
        gray: '#E2E8F0',
        disable: '#999999',
        black: '#282828',
        white: '#fff',
        link: '#0073ff',
        warning: '#F8B24F',
        warningLight: '#FFF6E8',
        info: '#E7EFFF',
        yellow: '#FFF500',
        greenBright: '#72bf44',

    },
    linkStyle: {
        color: '#0073ff'
    },
    default: {
        fontSize: 14,
        color: '#282828',
        lineHeight: '30px',
        fontFamily: '"Inter",sans-serif',
        btnBorderType: 'square'
    },
};

export default appTheme;
