export const events = [
    {
        title: "The Bio-IT World",
        date: "APRIL 2 - 4, 2025",
        location: "BOSTON, US",
        link: "https://www.bio-itworldexpo.com/",
    },
    {
        title: "Drug Discovery Chemistry",
        date: "APRIL 14 - 17, 2025",
        location: "SAN DIEGO, US",
        link: "https://www.drugdiscoverychemistry.com/",
    },
    {
        title: "13th International Conference on Chemical Structures",
        date: "JUNE 1 - 5, 2025",
        location: "HOLLAND",
        link: "https://iccs-nl.org/",
    },
    {
        title: "The Bio International Convention",
        date: "JUNE 16 - 19, 2025",
        location: "BOSTON, US",
        link: "https://convention.bio.org/bio-2025",
    },
    {
        title: "BioTechX. US",
        date: "SEPTEMBER 16 - 17, 2025",
        location: "PHILADELPHIA, US",
        link: "https://www.terrapinn.com/conference/biotechxusa/index.stm",
    },
    {
        title: "BioTechX. Europe",
        date: "OCTOBER 6 - 8, 2025",
        location: "BASEL, SWITZERLAND",
        link: "https://www.terrapinn.com/conference/biotechx/index.stm",
    },
];