import React from 'react';
import {isEmpty} from "../../../utils/validations";

const EnquireNowView = (props) => {
    const {closeModal,onBlurContent,handleChanges,handleSendMsg,errors,isMobileView} = props;

    return (
        <div>
            <div
                className="d-flex justify-content-center align-items-center position-fixed top-0 start-0 w-100 h-100"
                style={{zIndex: 1050}}
                onClick={closeModal}>
                <div
                    className="p-0 m-0 rounded"
                    style={{
                        width: isMobileView ? '80%' : '40%',
                        paddingInline:'24px',
                        maxWidth: '700px',
                        position: 'relative',
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div>
                        <div className="modal-content text-center modal-popup-main">
                            <div className="">
                                <div className="justify-content-center">
                                    <div
                                        className="bg-white"
                                        style={{
                                            padding: isMobileView ? '70px 20px 40px' : '70px 40px 40px 40px',
                                            borderRadius: '10px',
                                            boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.2)',
                                        }}>
                                        <button
                                            type="button"
                                            className="btn-close position-absolute top-0 end-0 mt-2 me-2"
                                            aria-label="Close"
                                            onClick={closeModal}
                                        />
                                        <div className="mb-2 d-block text-gray">
                                            Looking for a Demo?
                                        </div>
                                        <div className={isMobileView ? "section-sub-title mb-3" : "section-title mb-3"}>
                                            How can we help you?
                                        </div>
                                        <div className="mb-3">
                                            <input
                                                type="text"
                                                name="name"
                                                value={props.name || ''}
                                                className="form-control ps-3 shadow-none required"
                                                placeholder="Enter your name*"
                                                onBlur={()=>{onBlurContent('name')}}
                                                style={{border : isEmpty(errors.name) ? '1px solid var(--disable)' : '1px solid red'}}
                                                onChange={(e)=>{
                                                    handleChanges('name',e.target.value)
                                                }}
                                            />
                                            {!isEmpty(errors.name) &&
                                            <div className='text-danger section-text text-start'>
                                                Please enter name
                                            </div>
                                            }
                                        </div>
                                        <div className="mb-3">
                                            <input
                                                type="email"
                                                name="email"
                                                value={props.email || ''}
                                                className="form-control ps-3 required shadow-none"
                                                placeholder="Enter your email*"
                                                onBlur={()=>{onBlurContent('email')}}
                                                style={{border : isEmpty(errors.email) ? '1px solid var(--disable)' : '1px solid red'}}
                                                onChange={(e)=>{handleChanges('email',e.target.value)}}
                                            />
                                            {!isEmpty(errors.email) &&
                                            <div className='text-danger section-text text-start'>
                                                Please enter email
                                            </div>
                                            }
                                        </div>
                                        <div className="mb-3">
                                            <input
                                                type="tel"
                                                name="phone"
                                                value={props.mobile || ''}
                                                className="form-control ps-3"
                                                placeholder="Enter your phone"
                                                onChange={(e)=>{
                                                    handleChanges('mobile',e.target.value)
                                                }}
                                            />
                                        </div>
                                        <div className="mb-3">
                                                    <textarea
                                                        placeholder="Your message"
                                                        name="comment"
                                                        value={props.massage}
                                                        className="form-control ps-3 shadow-none"
                                                        rows="3"
                                                        onChange={(e)=>{handleChanges('massage',e.target.value)}}>
                                                    </textarea>
                                        </div>
                                        <button
                                            className="btn btn-medium btn-box-shadow btn-round-edge w-100 mt-3 py-3 bg-dark text-white section-text"
                                            onClick={()=>{handleSendMsg()}}>
                                            SEND MESSAGE
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default EnquireNowView;
