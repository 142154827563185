import React, {useRef, useState} from 'react';
import CareersView from "./careersView";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {isEmpty} from "../utils/validations";

import {sendCarrierData, uploadData} from "./carrierAction";
import store from "../store";
import {showToastMessage} from "../core/actions/appActions";
import {getFirstErrorId} from "../utils/helper";

function Careers(props) {
    const [careerState, setCareerState] = useState({
        isOpenFirstDiv: false,
        isOpenSecondDiv: false,
        isOpenThirdDiv: false,
        name: '',
        email: '',
        region: '',
        functions: '',
        massage: '',
        selectedDocument: null,
        isCVAttached:false,
        errors: {
            name: '',
            email: '',
            selectedDocument:''
        },
        scrollToFieldId : '',
        isShowLoader: false
    });

    const fileInputRef = useRef(null);

    const onChangeValue = (title, value) => {
        setCareerState((prevState) => ({
            ...prevState,
            [title]: value,
            errors: {
                name: title === "name" ? '' : prevState.errors.name,
                email: title === "email" ? '' : prevState.errors.email
            },
            isShowLoader: (title === 'isOpenFirstDiv' || title === 'isOpenSecondDiv')
        }));

        setTimeout(()=>{
            setCareerState((prevState) => ({
                ...prevState,
                isShowLoader: false
            }));
        }, 500)
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return !emailRegex.test(email);
    };
    const onBlurContent = (title) => {
        validateChanges(title)
    };

    const validateChanges = (title) => {
        let allErrors = [];
        let orderedErrorFields = ['name', 'email', 'document'];


        let flag = true;
        if (title === 'name' || title === 'send') {
            let errors = isEmpty(careerState.name);
            setCareerState((prevState) => ({
                ...prevState,
                errors: {
                    ...prevState.errors,
                    name: (errors ? 'Please enter name' : ''),
                }
            }));

            if (errors) {
                flag = false;
                allErrors.push('name')
            }
        }
        if (title === 'email' || title === 'send') {
            let errors = isValidEmail(careerState.email);
            let errMsg = '';
            if (careerState.email === '') {
                errMsg = 'Please enter email'
            } else if (errors) {
                errMsg = 'Please enter valid email'
            }
            setCareerState((prevState) => ({
                ...prevState,
                errors: {
                    ...prevState.errors,
                    email: (errMsg !== '' ? errMsg : ''),
                },
            }));
            if (!isEmpty(errMsg)) {
                flag = false;
                allErrors.push('email')
            }
        }

        if (title === 'document' || title === 'send') {
            let isUploaded = (careerState.selectedDocument === null );
            setCareerState((prevState) => ({
                ...prevState,
                isCVAttached: isUploaded,
                errors: {
                    ...prevState.errors,
                    selectedDocument: (isUploaded ? 'Please upload CV' : '')
                }
            }));
            if (isUploaded) {
                flag = false;
                allErrors.push('document')
            }
        }
        setCareerState((prevState) => ({
            ...prevState,
            scrollToFieldId: getFirstErrorId(orderedErrorFields, allErrors)
        }));
        return flag;
    };

    const onFormChange = (title, value) => {
        setCareerState((prevState) => ({
            ...prevState,
            [title]: value
        }));
    };

    const clearInputs = () => {
        setCareerState((prevState) => ({
            ...prevState,
            name: '',
            email: '',
            region: '',
            functions: '',
            massage: '',
            selectedDocument: null,
            isCVAttached:false,
            errors: {
                ...prevState.errors,
                name: '',
                email: '',
                selectedDocument:''
            }
        }));
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };


    const handleSend = () => {
        const {name, email, region, functions, massage, selectedDocument} = careerState;
        if (!validateChanges('send')) {
            return
        }

        let payload = {};
        const choosenFile = selectedDocument;

        uploadData(choosenFile).then((response) => {
            payload = {
                name: name,
                email: email,
                region: region,
                jobProfile: functions,
                message: massage,
                serverFileName: response?.serverFileName,
                originalFileName: response?.originalFileName,
            };

            sendCarrierData(payload).then((res) => {
                if (res.success) {
                    store.dispatch(showToastMessage('success', 'Thank you for your job application'));
                    clearInputs()
                }
            })
        })

    };

    const handleAttachmentUploadEvent = (event) => {
        if (!isEmpty((event?.target?.files))) {
            setCareerState((prevState) => ({
                ...prevState,
                selectedDocument: event?.target?.files[0],
                isCVAttached:false
            }));
        }
    };

    return (
        <CareersView
            {...props}
            {...careerState}
            fileInputRef={fileInputRef}
            onChangeValue={onChangeValue}
            onFormChange={onFormChange}
            handleAttachmentUploadEvent={handleAttachmentUploadEvent}
            onBlurContent={onBlurContent}
            handleSend={handleSend}
        />
    )

}


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    isXLView: state.appState.deviceInfo.isXLView,
});

export default connect(mapStateToProps, {})(withTranslation('translations')(Careers));