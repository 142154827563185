import React, {useRef, useState} from 'react';
import {Link} from "react-router-dom";
import useOnClickOutSide from "../../components/Hooks/useClickOutside";
import "./menuBar.css"
import appTheme from "../../../assets/appTheme";
let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

function Hamburger(props) {
    const {handleHamburgerClick} = props;
    const [isDataOpen, setIsDataOpen] = useState(false);
    const [isBiowareOpen, setIsBiowareOpen] = useState(false);
    const [isServicesOpen, setIsServicesOpen] = useState(false);
    const [isCorporateOpen, setIsCorporateOpen] = useState(false);

    const ref = useRef();
    const currentPath = (props.location.pathname);

    const handleDataClick = () => {
        setIsDataOpen(!isDataOpen)
        if(!isDataOpen){
            setIsBiowareOpen(false)
            setIsServicesOpen(false)
            setIsCorporateOpen(false)
        }
    };

    const handleBiowareClick = () => {
        setIsBiowareOpen(!isBiowareOpen)
        if(!isBiowareOpen){
            setIsDataOpen(false)
            setIsServicesOpen(false)
            setIsCorporateOpen(false)
        }
    };

    const handleServicesClick = () => {
        setIsServicesOpen(!isServicesOpen)
        if(!isServicesOpen){
            setIsDataOpen(false)
            setIsBiowareOpen(false)
            setIsCorporateOpen(false)
        }
    };

    const handleCorporateClick = () => {
        setIsCorporateOpen(!isCorporateOpen)
        if(!isCorporateOpen){
            setIsDataOpen(false)
            setIsBiowareOpen(false)
            setIsServicesOpen(false)
        }
    };

    useOnClickOutSide(ref, () => {
        handleHamburgerClick()
    });

    return (
        <div className='theme-bg-white position-fixed position-fixed top-0'
             style={{
                 width: '100%',
                 overflow: "auto",
                 boxShadow: '0 20px 15px 0 rgba(23,23,23,.05)'
             }}
             ref={ref}>
            <div
                style={{
                    padding: '15px 0px',
                }}>
                <div className="d-flex justify-content-between align-items-center page-container pt-0 mb-4">

                    <div className="px-lg-3 px-0 evolvus-label-mobile cursor">
                        <img src={s3BaseURL + '/Images/general/evolvusLogo.png'}
                             style={{height: '56px', paddingTop: 4, paddingBottom: 4}}
                             onClick={() => props.history.push('/')}
                             className='img-fluid cursor'
                             alt={''}/>
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                        <i className="fa-solid fa-xmark theme-text-black cursor" style={{fontSize: 24}}
                           onClick={() => handleHamburgerClick()}/>
                    </div>
                </div>
                <div className='page-container'>
                    <div className='py-1 block-container-navbar page-container'
                         style={{
                             fontSize: 16,
                         }}
                         onClick={() => {
                             handleHamburgerClick()
                         }}>
                        <a className='fs-16 actionable-link theme-text-on-secondary-bg theme-font-semiBold'
                           href={'/'}>
                            Home
                        </a>
                    </div>
                    <div>
                        <div className='d-flex align-items-center py-1 block-container-navbar justify-content-between '
                             onClick={() => handleDataClick(!isDataOpen)}>
                            <div className='fs-16 actionable-link theme-text-on-secondary-bg theme-font-semiBold'> Data</div>
                            {isDataOpen ?
                                <i className="fa-solid fa-angle-up theme-text-on-secondary-bg cursor"
                                   style={{fontSize: 'var(--font-size-sm)'}}/>
                                :
                                <i className="fa-solid fa-angle-down theme-text-on-secondary-bg cursor"
                                   style={{fontSize: 'var(--font-size-sm)'}}/>
                            }
                        </div>
                        {isDataOpen &&
                        <div className='d-flex flex-column block-container-navbar-child'
                             style={{paddingLeft: 50}}>
                            <div>
                                <div className='cursor'
                                     onClick={() => {
                                         handleDataClick();
                                         handleHamburgerClick()
                                     }}>
                                    <Link
                                        className={currentPath === "/data/liceptor-database" ? 'fs-15 navbar navbar navbar feature-menu-selected menu-font-child-item theme-text-on-secondary-bg' : 'fs-15 navbar navbar  navbar menu-font-child-item theme-text-on-secondary-bg'}
                                        to={'/data/liceptor-database'}>
                                        Liceptor Database
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <div className='cursor'
                                     onClick={() => {
                                         handleDataClick();
                                         handleHamburgerClick()
                                     }}>
                                    <Link
                                        className={currentPath === "/data/liceptor-database" ? 'fs-15 navbar navbar feature-menu-selected menu-font-child-item theme-text-on-secondary-bg' : 'fs-15 navbar navbar menu-font-child-item theme-text-on-secondary-bg'}
                                        to={'/data/oncogen-database'}>
                                        Oncogen database
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <div className='cursor'
                                     onClick={() => {
                                         handleDataClick();
                                         handleHamburgerClick()
                                     }}>
                                    <Link
                                        className={currentPath === "/data/liceptor-database" ? 'fs-15 navbar navbar feature-menu-selected menu-font-child-item theme-text-on-secondary-bg' : 'fs-15 navbar navbar menu-font-child-item theme-text-on-secondary-bg'}
                                        to={'/data/theme-database'}>
                                        Theme Database
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <div className='cursor'
                                     onClick={() => {
                                         handleDataClick();
                                         handleHamburgerClick()
                                     }}>
                                    <Link
                                        className={currentPath === "/data/liceptor-database" ? 'fs-15 navbar navbar feature-menu-selected menu-font-child-item theme-text-on-secondary-bg' : 'fs-15 navbar navbar menu-font-child-item theme-text-on-secondary-bg'}
                                        to={'/data/proximers-database'}>
                                        Proximers Database
                                    </Link>
                                </div>
                            </div>

                            <div>
                                <div className='cursor'
                                     onClick={() => {
                                         handleDataClick();
                                         handleHamburgerClick()
                                     }}>
                                    <Link
                                        className={currentPath === "/data/liceptor-database" ? 'fs-15 navbar navbar feature-menu-selected menu-font-child-item theme-text-on-secondary-bg' : 'fs-15 navbar navbar menu-font-child-item theme-text-on-secondary-bg'}
                                        to={'/data/adme-database'}>
                                        ADME database
                                    </Link>
                                </div>
                            </div>

                            <div>
                                <div className='p-3 m-1 navbar'
                                     style={{color: appTheme.appColor.grayDark, border: "none"}}>
                                    Connector Base
                                </div>
                                {/*<div onClick={() => {*/}
                                {/*    handleDataClick();*/}
                                {/*    handleHamburgerClick()*/}
                                {/*}}*/}
                                {/*     className='cursor'>*/}
                                {/*    <Link*/}
                                {/*        className={currentPath === "/data/liceptor-database" ? 'fs-15 border-none navbar navbar feature-menu-selected menu-font-child-item theme-text-on-secondary-bg' : 'fs-15 border-none navbar navbar menu-font-child-item theme-text-on-secondary-bg'}*/}
                                {/*        to={'/data/liceptor-database'}>*/}
                                {/*        Connector database*/}
                                {/*    </Link>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        }
                    </div>
                    {/*Industries*/}
                    <div>
                        <div className='d-flex align-items-center pb-1 block-container-navbar justify-content-between'
                             onClick={() => {
                                 // handleBiowareClick(!isBiowareOpen)
                             }}>
                            <div className='fs-16 accordian-title theme-text-on-secondary-bg theme-font-semiBold'> Bioware</div>
                            {/*{isBiowareOpen ?*/}
                            {/*    <i className="fa-solid fa-angle-up theme-text-on-secondary-bg cursor"*/}
                            {/*       style={{fontSize: 'var(--font-size-sm)'}}/>*/}
                            {/*    :*/}
                            {/*    <i className="fa-solid fa-angle-down theme-text-on-secondary-bg cursor"*/}
                            {/*       style={{fontSize: 'var(--font-size-sm)'}}/>*/}
                            {/*}*/}
                        </div>
                        {isBiowareOpen &&
                        <div className='d-flex flex-column block-container-navbar-child' style={{paddingLeft: 50}}>
                            <div>
                                <div className='cursor'
                                     onClick={() => {
                                         handleBiowareClick();
                                         handleHamburgerClick()
                                     }}>
                                    <Link
                                        className={currentPath === "/bioware/ai-model" ? 'fs-15 navbar navbar usecases-menu-selected menu-font-child-item theme-text-on-secondary-bg' : 'fs-15 navbar navbar menu-font-child-item theme-text-on-secondary-bg'}
                                        to={'/bioware/ai-model'}>
                                        AI Model
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <div className='cursor'
                                     onClick={() => {
                                         handleBiowareClick();
                                         handleHamburgerClick()
                                     }}>
                                    <Link
                                        className={currentPath === "/bioware/discovery_platforms" ? 'fs-15 navbar navbar usecases-menu-selected menu-font-child-item theme-text-on-secondary-bg border-none' : 'fs-15 border-none navbar navbar menu-font-child-item theme-text-on-secondary-bg'}
                                        to={'/bioware/discovery_platforms'}>
                                        Discovery Platforms
                                    </Link>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    {/*Industries*/}
                    <div>
                        <div className='d-flex align-items-center pb-1 block-container-navbar justify-content-between'
                             onClick={() => handleServicesClick(!isServicesOpen)}>
                            <div className='fs-16 accordian-title theme-text-on-secondary-bg theme-font-semiBold'> Services</div>
                            {isServicesOpen ?
                                <i className="fa-solid fa-angle-up theme-text-on-secondary-bg cursor"
                                   style={{fontSize: 'var(--font-size-sm)'}}/>
                                :
                                <i className="fa-solid fa-angle-down theme-text-on-secondary-bg cursor"
                                   style={{fontSize: 'var(--font-size-sm)'}}/>
                            }
                        </div>
                        {isServicesOpen &&
                        <div className='d-flex flex-column block-container-navbar-child' style={{paddingLeft: 50}}>
                            <div>
                                <div className='cursor'
                                     onClick={() => {
                                         handleServicesClick();
                                         handleHamburgerClick()
                                     }}>
                                    <Link
                                        className={currentPath === "/services/data-curation" ? 'fs-15 navbar usecases-menu-selected menu-font-child-item theme-text-on-secondary-bg' : ' fs-15 navbar menu-font-child-item theme-text-on-secondary-bg'}
                                        to={'/services/data-curation'}>
                                        Data Curation
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <div className='cursor'
                                     onClick={() => {
                                         handleServicesClick();
                                         handleHamburgerClick()
                                     }}>
                                    <Link
                                        className={currentPath === "/services/research-info" ? 'fs-15 navbar usecases-menu-selected menu-font-child-item theme-text-on-secondary-bg' : 'fs-15 navbar menu-font-child-item theme-text-on-secondary-bg'}
                                        to={'/services/research-info'}>
                                        Research Informatics
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <div className='cursor'
                                     onClick={() => {
                                         handleServicesClick();
                                         handleHamburgerClick()
                                     }}>
                                    <Link
                                        className={currentPath === "/services/bio-info" ? 'fs-15 navbar usecases-menu-selected menu-font-child-item theme-text-on-secondary-bg' : 'fs-15 navbar menu-font-child-item theme-text-on-secondary-bg'}
                                        to={'/services/bio-info'}>
                                        Bioinformatics
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <div className='cursor'
                                     onClick={() => {
                                         handleServicesClick();
                                         handleHamburgerClick()
                                     }}>
                                    <Link
                                        className={currentPath === "/services/chem-info" ? 'fs-15 navbar usecases-menu-selected menu-font-child-item theme-text-on-secondary-bg' : 'fs-15 navbar menu-font-child-item theme-text-on-secondary-bg'}
                                        to={'/services/chem-info'}>
                                        Cheminformatics
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <div className='cursor'
                                     onClick={() => {
                                         handleServicesClick();
                                         handleHamburgerClick()
                                     }}>
                                    <Link
                                        className={currentPath === "/services/clinical" ? 'fs-15  border-none navbar usecases-menu-selected menu-font-child-item theme-text-on-secondary-bg' : 'fs-15 border-none navbar menu-font-child-item theme-text-on-secondary-bg'}
                                        style={{border: "none"}}
                                        to={'/services/clinical'}>
                                        Clinical Data
                                    </Link>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    <div>
                        <div className='block-container-navbar'
                             style={{
                                 fontSize: 16,
                             }}
                             onClick={() => {
                                 handleHamburgerClick()
                             }}>
                            <Link className='fs-16 actionable-link theme-text-on-secondary-bg theme-font-semiBold' to={'/careers'}>
                                Careers
                            </Link>
                        </div>
                    </div>
                    {/*Industries*/}
                    <div>
                        <div className='d-flex align-items-center pb-1  block-container-navbar justify-content-between'
                             onClick={() => handleCorporateClick(!isCorporateOpen)}>
                            <div className='fs-16 accordian-title theme-text-on-secondary-bg theme-font-semiBold'>    Corporate</div>
                            {isCorporateOpen ?
                                <i className="fa-solid fa-angle-up theme-text-on-secondary-bg cursor"
                                   style={{fontSize: 'var(--font-size-sm)'}}/>
                                :
                                <i className="fa-solid fa-angle-down theme-text-on-secondary-bg cursor"
                                   style={{fontSize: 'var(--font-size-sm)'}}/>
                            }
                        </div>
                        {isCorporateOpen &&
                        <div className='d-flex flex-column block-container-navbar-child' style={{paddingLeft: 50}}>
                            <div>
                                <div className='cursor'
                                     onClick={() => {
                                         handleCorporateClick();
                                         handleHamburgerClick()
                                     }}>
                                    <Link
                                        className={currentPath === "/about" ? 'fs-15 navbar navbar usecases-menu-selected menu-font-child-item theme-text-on-secondary-bg' : 'fs-15 navbar navbar menu-font-child-item theme-text-on-secondary-bg'}
                                        to={'/about'}>
                                        About
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <div className='cursor'
                                     onClick={() => {
                                         handleCorporateClick();
                                         handleHamburgerClick()
                                     }}>
                                    <Link
                                        className={currentPath === "/team" ? 'fs-15 navbar navbar usecases-menu-selected menu-font-child-item theme-text-on-secondary-bg' : 'fs-15 navbar navbar menu-font-child-item theme-text-on-secondary-bg'}
                                        to={'/team'}>
                                        Team
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <div className='cursor'
                                     onClick={() => {
                                         handleCorporateClick();
                                         handleHamburgerClick()
                                     }}>
                                    <Link
                                        className={currentPath === "/newsAndEvents" ? 'fs-15 border-none navbar navbar usecases-menu-selected menu-font-child-item theme-text-on-secondary-bg' : 'fs-15 border-none navbar navbar menu-font-child-item theme-text-on-secondary-bg'}
                                        style={{border: "none"}}
                                        to={'/newsAndEvents'}>
                                        News & Events
                                    </Link>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    <div className={'w-100 text-center pt-3'}
                         style={{marginBottom: 10}}>
                        <button
                            onClick={() => {
                                props.history.push('/contactus');
                            }}
                            style={{
                                color: 'var(--white)',
                                fontSize: '16px',
                                fontWeight: 500,
                                width: 'auto',
                                backgroundImage: 'linear-gradient(to right, #0066b3, #72bf44, #72bf44)',
                                backgroundSize: "200% auto",
                                backgroundColor: 'transparent',
                                borderRadius: 50,
                                letterSpacing: 0.8,
                                fontFamily: 'Assistant, sans-serif',
                                borderColor: 'transparent',
                                border: '2px solid transparent',
                                padding: '7px 26px'
                            }}>
                            <div>CONTACT US</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default (Hamburger);