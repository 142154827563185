import React from "react";
import TabUiView from "./tabUiView";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL + '/Images/data';
let s3BaseURL2 = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL + '/Images/home';

const DataHeaderView = (props) => {

    const renderImageHeaderUI = () => {
        return (
            <div className="pt-0 cover-background top-space-margin sm-pb-0 mb-3"
                 style={{
                     backgroundImage: `url(${s3BaseURL + '/dataBanner.jpg'})`,
                 }}>
                <div className="page-container">
                    <div className="row align-items-end h-200px sm-h-100px ">
                        <div className="col-lg-9 col-md-6 position-relative text-left page-title-extra-large d-flex justify-content-left align-item-end">
                            <div className="justify-content-left header-img-text"> Data</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderDataHeaderUI = () => {
        return (
            <div className="bg-white py-4">
                <div className="page-container">
                    <div className=" px-2">
                        <div className=" section-title mb-3">
                            Our Databases</div>
                        <div className='section-text text-start text-gray'>
                            <div className='mb-3'>
                                We produce some of the worlds largest curated datasets. The databases are created as per the
                                requirements of the pharmaceutical industry.
                            </div>
                            <div className='mb-3'>
                                The effectiveness of our databases has been proved in prioritising QSAR and pharmacophore
                                studies, synthetic chemistry endeavours and advancing 'Hit-to-Lead' patterns.
                            </div>
                            <div className='mb-3'>
                                The effectiveness of our databases has been proved in prioritising QSAR and pharmacophore
                                studies, synthetic chemistry endeavours and advancing 'Hit-to-Lead' patterns.
                                Big Pharma and biotech customers have been effectively using our databases to mine chemical
                                space both for internal research and to track competitor patent profiles for compounds of
                                interest to them.
                            </div>
                            The effectiveness of our databases has been proved in prioritising QSAR and pharmacophore
                            studies, synthetic chemistry endeavours and advancing 'Hit-to-Lead' patterns.
                            Big Pharma and biotech customers have been effectively using our databases to mine chemical
                            space both for internal research and to track competitor patent profiles for compounds of
                            interest to them.

                            The latest use cases being, the emerging area of LLM's. Our databases power some of the
                            worlds largest LLM's
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderCardsUI = () => {
        const {isSMView, isXSView} = props;
        if (!isSMView && !isXSView){
            return (

                <TabUiView
                    {...props}
                    isMainScreen={true}
                />
            )
        }

        return (
            <div className="page-container  ">
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 justify-content-center">
                    <div className={isXSView ? "px-2 mb-5" : "px-4 mb-5"}>
                        <div className={isSMView ? "px-5" :"mx-3"}>
                            <div className={`card-box-shadow text-center transition-inner-all d-flex align-items-center h-100`}
                            >
                                <div className="card-padding cursor"
                                     onClick={()=> props.history.push("/data/liceptor-database")}>
                                    <div className='mb-4'>
                                        <img src={s3BaseURL2+ '/Liceptor.png'}
                                             alt="Liceptor Logo"
                                             className="mb-3 image-tag" />
                                    </div>
                                    <div className="block-sub-title">Small molecule</div>
                                    <div className="block-sub-title">bioactivity Database</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={isXSView ? "px-2 mb-5" : "px-4 mb-5"}>
                        <div className={isSMView ? "px-5" :"mx-3"}>
                            <div className={`card-box-shadow text-center d-flex align-items-center h-100  `}
                            >
                                <div className="card-padding cursor"
                                     onClick={()=> props.history.push("/data/oncogen-database")}>
                                    <div className='mb-4'>
                                        <img src={s3BaseURL2+ '/Oncogen.png'}
                                             alt="Oncogen Logo"
                                             className="mb-3 image-tag" />
                                    </div>
                                    <div className="block-sub-title">Cancer genomics curated</div>
                                    <div className="block-sub-title">knowledgebase</div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={isXSView ? "px-2 mb-5" : "px-4 mb-5"}>
                        <div className={isSMView ? "px-5" :"mx-3"}>
                            <div className={`card-box-shadow text-center d-flex align-items-center h-100`}
                            >
                                <div className="card-padding cursor"
                                     onClick={()=> props.history.push("/data/theme-database")}>
                                    <div className='mb-4'>
                                        <img src={s3BaseURL2+ '/Theme.png'}
                                             alt="Theme Logo"
                                             className="mb-3 image-tag"
                                        />
                                    </div>
                                    <div className="block-sub-title">Biotransformation</div>
                                    <div className="block-sub-title">reactions DB</div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={isXSView ? "px-2 mb-5" : "px-4 mb-5"}>
                        <div className={isSMView ? "px-5" :"mx-3"}>
                            <div className={`card-box-shadow text-center d-flex align-items-center h-100 `}
                            >
                                <div className="card-padding cursor"
                                     onClick={()=> props.history.push("/data/proximers-database")}>
                                    <div className='mb-4'>
                                        <img src={s3BaseURL2+ '/Proximers.png'}
                                             alt="Proximers Logo"
                                             className="mb-3 image-tag"
                                        />
                                    </div>
                                    <div className="block-sub-title">Protein degraders, MG</div>
                                    <div className="block-sub-title">BMC datasets</div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={isXSView ? "px-2 mb-5" : "px-4 mb-5"}>
                        <div className={isSMView ? "px-5" :"mx-3"}>
                            <div className={`card-box-shadow text-center d-flex align-items-center h-100`}
                            >
                                <div className="card-padding cursor"
                                     onClick={()=> props.history.push("/data/adme-database")}>
                                    <div className='mb-4'>
                                        <img src={s3BaseURL2+ '/adme.png'}
                                             alt="ADME Logo"
                                             className="mb-3 image-tag"
                                        />
                                    </div>
                                    <div className="block-sub-title">Curated PK endpoints</div>
                                    <div className="block-sub-title"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
    return (
        <div className=''>
            {renderImageHeaderUI()}
            {renderDataHeaderUI()}
            <div className="page-container">
                {renderCardsUI()}
            </div>

        </div>
    )
};
export default DataHeaderView;