import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import AIModelView from "./AIModelView";


const AIModel = (props) => {
    const imageRef = useRef(null);
    const [isVisibleDevelopment, setIsVisibleDevelopment] = useState(false);
    const [isVisibleCast, setIsVisibleCast] = useState(false);



    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            if (scrollTop > 600 && scrollTop < 1000){
                setIsVisibleDevelopment(true);
            }
            if (scrollTop > 830 ){
                setIsVisibleCast(true);
            }

            const elements = document.querySelectorAll('.scroll-animation');

            elements.forEach((element) => {
                const rect = element.getBoundingClientRect();
                const windowHeight = window.innerHeight;
                console.log('windowHeight', windowHeight, rect.bottom, rect.top)
                // Check if the element is in view
                if (rect.bottom > 600 && rect.top < 350) {
                    element.classList.add('animate');
                } else {
                    element.classList.remove('animate'); // Optional: Reset the animation
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Check on mount to handle initial visibility

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <AIModelView
            {...props}
            isVisibleDevelopment={isVisibleDevelopment}
            isVisibleCast={isVisibleCast}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    isMDView: state.appState.deviceInfo.isMDView,
});

export default connect(mapStateToProps, {})(withTranslation('translations')(AIModel));
