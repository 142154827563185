import React, {useState} from 'react';
import EnquireNowView from "./enquireNowView";
import {sendEnquiry} from "../dataCurationAction"
import {isEmpty} from "../../../utils/validations";
import store from "../../../store";
import {showToastMessage} from "../../../core/actions/appActions";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
const EnquireNow = (props) => {

    const {closeModal} = props;

    const [contactForm, setContactForm] = useState({
        name: '',
        email: '',
        mobile: '',
        massage: '',
        errors: {
            name: '',
            email: '',
        }
    });

    const handleChanges = (title, value) => {
        setContactForm((prevState) => ({
            ...prevState,
            [title]: value,
            errors: {
                name: title === "name" ? '' : prevState.errors.name,
                email: title === "email" ? '' : prevState.errors.email
            }
        }));
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return !emailRegex.test(email);
    };
    const onBlurContent = (title) => {
        validateChanges(title)
    };

    const validateChanges = (title) => {
        let flag = true;
        if (title === 'name' || title === 'send') {
            let errors = isEmpty(contactForm.name);
            setContactForm((prevState) => ({
                ...prevState,
                errors: {
                    ...prevState.errors,
                    name: (errors ? 'Please enter name' : '')
                }
            }));
            if (errors){
                flag = false;
            }
        }
        if (title === 'email' || title === 'send') {
            let errors = isValidEmail(contactForm.email);
            let errMsg = '';
            if (contactForm.email === '') {
                errMsg = 'Please enter email'
            } else if (errors) {
                errMsg = 'Please enter valid email'
            }
            setContactForm((prevState) => ({
                ...prevState,
                errors: {
                    ...prevState.errors,
                    email: (errMsg !== '' ? errMsg : '')
                }
            }));
            if (!isEmpty(errMsg)){
                flag = false;
            }

        }
        return flag;
    };

    const clearInputs = () => {
        setContactForm((prevState) => ({
            ...prevState,
            name: '',
            email: '',
            mobile: '',
            massage: '',
            errors: {
                ...prevState.errors,
                name: '',
                email: '',
            }
        }));
    };

    const handleSendMsg = () => {
        const {name, email, mobile, massage} = contactForm;
        if (!validateChanges('send')) {
            return
        }

        let payload = {
            "name": name,
            "email": email,
            "phoneNumber" : mobile,
            "message" : massage
        };


        sendEnquiry(payload).then(res => {
            if (res?.success) {
                closeModal();
                store.dispatch(showToastMessage('success', 'Your request is submitted.'));
                clearInputs();
            }
        })
    };


    return (

        <EnquireNowView
            {...props}
            {...contactForm}
            closeModal={closeModal}
            onBlurContent={onBlurContent}
            handleChanges={handleChanges}
            handleSendMsg={handleSendMsg}
        />
    );

};
const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(withTranslation('translations')(EnquireNow));

