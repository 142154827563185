import React from 'react';
import appTheme from "../../../assets/appTheme";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

function Footer(props) {

    const {isMobileView, isLGView} = props;

    const renderLogo = () => {
        return (
            <div className="text-start"
            style={{flex: 2}}>
                <div>
                    <a href="/" className={"footer-logo mb-15px d-block d-lg-inline-block" + (isMobileView ? " mb-1" : " mb-3")}>
                        <img
                            src={s3BaseURL + '/Images/general/evolvusLogo.png'}
                            alt=''
                            style={{
                                maxWidth: isMobileView ? '80px' : '117px'
                            }}
                            className='image-tag'
                            data-at2x="images/logo.png"/>
                    </a>
                </div>
                <div className="w-80 sm-w-100 d-inline-block mb-15px"
                style={{
                    fontSize: 'var(--font-size-sm)'
                }}>info@evolvus.com</div>
            </div>
        )
    };

    const renderCompanyDetails = () => {
        return (
            <div
                className="text-start"
                style={{flex: isMobileView ?  1.5 : 1.5}}>
                <span className="text-black d-block  mb-5px footer-heading">
                    Company
                </span>
                <div>
                    <a className={'theme-font-content cursor footer-company-link'}
                        onClick={() => {
                            props.history.push('/about')
                        }}>
                        Who we are
                    </a>
                </div>
                <div className='d-flex align-items-center'>
                    <a className='theme-font-content cursor footer-company-link'
                       onClick={() => {
                            props.history.push({
                                pathname: "/data",
                                state: {Dataheading: "Liceptordatabase"}
                            })
                        }}>
                        Data
                    </a>
                    <div style={{
                        backgroundColor: '#0066b3',
                        borderRadius: 20,
                        color: appTheme.appColor.white,
                        padding: '0px 8px',
                        fontSize: 'var(--font-size-xxs)',
                        maxWidth: "max-content",
                        textTransform: 'uppercase',
                        maxHeight: '25px',
                        alignItems: "center",
                        lineHeight: '25px'
                    }} className='ms-2'>
                        Interesting
                    </div>
                </div>

                <div>
                    <a className='theme-font-content cursor footer-company-link'
                        onClick={() => {
                            props.history.push('/contactus')
                        }}>
                        Contact us
                    </a>
                </div>
            </div>
        )
    };

    const renderAddress = () => {
        return (
            <div
                className="text-start"
                style={{flex: 2}}>
                            <span className="text-black d-block mb-5px footer-heading">
                                North America</span>
                <div className="w-80 lg-w-100 md-w-70 text-black  sm-w-100 mb-10px theme-font-content primary-font"
                   style={{lineHeight: '30px'}}>2 Forsythia Drive, Nashua,
                    <div>NH-03062
                        USA
                    </div>
                </div>
            </div>
        )
    };

    const renderAddressDetails = () => {
        return (
            <div
                className="text-start"
                style={{flex: isMobileView ?  1.5 :2}}>
                <span className="alt-font d-block mb-5px footer-heading text-black">Europe</span>
                <div className="w-90 lg-w-100 md-w-70 text-black  sm-w-100 mb-10px primary-font theme-font-content"
                     style={{lineHeight: '30px'}}> Altenhöferallee 3
                </div>
                <div className='theme-font-content text-black'>
                    60438 Frankfurt am
                </div>
                <div className='theme-font-content text-black'>Main, Germany</div>
            </div>
        )
    };

    const renderContactUs = () => {
        return (
            <div
                className="text-start"
                style={{flex: 2}}>
                <div
                    className={"alt-font d-inline-block align-middle text-black mb-0 me-35px md-me-0 position-relative" + (isMobileView ? " mb-1 me-3" : " mb-2")}
                    style={{fontSize: 24, fontWeight: 'var(--font-style-low)'}}>
                    Contact us Today <span
                    className="text-decoration-line-bottom-medium fw-600 font-style-italic"/>
                </div>
                <button className="gradient-btn"
                        onClick={()=>{
                            props.history.push('/contactus')
                        }}>
                    Contact Us
                </button>
            </div>
        )
    };

    const renderDesktopView = () => {
        return(
            <div className="d-flex justify-content-center pb-2 mb-4 px-3">
                {renderLogo()}

                {renderCompanyDetails()}

                {renderAddress()}

                {renderAddressDetails()}

                {renderContactUs()}

            </div>
        )
    };

    const renderMobileView = () => {
        return(
            <div>
                <div className="d-flex align-items-start justify-content-center mb-4">
                    {renderLogo()}

                    {renderCompanyDetails()}

                </div>
                <div className="d-flex justify-content-center mb-4">
                    {renderAddress()}

                    {renderAddressDetails()}

                </div>
                <div className="d-flex align-items-center justify-content-center mb-4">
                    {renderContactUs()}
                </div>
            </div>

        )
    };

    return (
        <div>
            <div className={(isMobileView ? "pt-3 mb-5 pb-4 " : "py-5 ") +"sm-pt-40px sm-pb-45px footer-dark bg-light"}>

                <div className={isLGView ? "px-3" :"page-container mb-3 pt-4"}>

                    {isMobileView ? renderMobileView () : renderDesktopView()}
                    <div className="justify-content-center w-100 ">
                        <div className={(isLGView ? "px-5 " : "" ) +"lh-22 text-gray text-center"}
                             style={{
                                 width: '100%',
                                 fontSize: 'var(--font-size-xs)',
                                 lineHeight: isMobileView ? 'var(--line-spacing-20)' : ''
                             }}>
                            2024 Evolvus.com Disclaimer –
                            The contents on this website are solely owned by Evolvus, and is subject
                            to local and International copyright and trademark laws. All content is
                            provided on “as-is” basis. Any copying, replication, distribution, internet
                            scrapping, imitation is strictly prohibited.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    isLGView: state.appState.deviceInfo.isLGView,
});

export default connect(mapStateToProps, {})(withTranslation('translations')(Footer));
