import React from 'react';
import Footer from "../../core/layout/footer/footer";
let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

function PartnersView(props) {
    const {isXSView, isMobileView} = props;
    const renderHeader = () => {
        return(
            <section className="pt-0 cover-background top-space-margin sm-pb-0"
                     style={{
                         backgroundImage: `url(${s3BaseURL + '/Images/partners/partners.jpg'})`,
                     }}>
                <div className="container">
                    <div className="row align-items-end  h-200px sm-h-100px">
                        <div
                            className="col-lg-9 col-md-6 position-relative text-left page-title-extra-large d-flex flex-wrap flex-column align-items-left justify-content-left slideUpSlowly"
                            data-anime="{ &quot;el&quot;: &quot;childs&quot;, &quot;translateY&quot;: [30, 0], &quot;opacity&quot;: [0,1], &quot;duration&quot;: 600, &quot;delay&quot;: 0, &quot;staggervalue&quot;: 300, &quot;easing&quot;: &quot;easeOutQuad&quot; }" style={{animationDelay: '0ms', animationDuration: '600ms', animationTimingFunction: 'ease-out'}}>

                            <h1 className="justify-content-left text-white fw-600 ls-minus-0px"> Partners</h1>

                        </div>

                    </div>
                </div>
            </section>
        )
    };

    const renderCards = () => {
        return(
            <section id="down-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 px-0">
                            <div className={isMobileView ? isXSView ? '' : 'd-flex flex-wrap' : 'd-flex'}>
                                <div className={isMobileView ? isXSView ? 'slideUpFadeIn' : 'w-50' : 'slideUpFadeIn'} style={{padding: '20px',animationDelay: '200ms'}}>
                                    <div className="card bg-transparent border-0 h-100">
                                        <div className="blog-image position-relative overflow-hidden border-radius-4px">
                                            <a>
                                                <img src={s3BaseURL + '/Images/general/publicationSectionBanner.jpg'} alt/>
                                            </a>
                                        </div>
                                        <div
                                            className="card-body px-0 pt-30px pb-30px xs-pb-15px last-paragraph-no-margin">
                                            <span className="fs-14 text-uppercase"><a href="#"
                                                                                      className="blog-date text-dark-gray fw-600">31 August 2024</a></span>
                                            <a href="#"
                                               className="card-title mb-15px fw-500 fs-18 lh-30 text-dark-gray d-inline-block">Evolvus
                                                has opened its 3rd office at Symphony IT park</a>
                                            <p className="w-95 sm-w-100 corporate-subtext">Evolvus Technologies has recently expanded by
                                                opening its third
                                                office at Symphony IT Park. This new office strengthens their presence
                                                in the
                                                region, providing enhanced capacity for delivering data informatics
                                                solutions. The
                                                expansion aligns with the company's growth strategy, enabling them to
                                                better serve
                                                their clients while accommodating the increasing demand for their
                                                services. With a
                                                modern infrastructure and a vibrant workspace, this new location is set
                                                to further
                                                support their mission of innovation and excellence in the field of data
                                                informatics.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={isMobileView ? isXSView ? 'slideUpFadeIn' : 'w-50' : 'slideUpFadeIn'} style={{padding: '20px',animationDelay: '500ms'}}>
                                    <div className="card bg-transparent border-0 h-100">
                                        <div className="blog-image position-relative overflow-hidden border-radius-4px">
                                            <a>
                                                <img src={s3BaseURL + '/Images/general/publicationSectionBanner.jpg'} alt/>
                                            </a>
                                        </div>
                                        <div
                                            className="card-body px-0 pt-30px pb-30px xs-pb-15px last-paragraph-no-margin">
                                            <span className="fs-14 text-uppercase"><a href="#"
                                                                                      className="blog-date text-dark-gray fw-600">31 August 2024</a></span>
                                            <a href="#"
                                               className="card-title mb-15px fw-500 fs-18 lh-30 text-dark-gray d-inline-block">Evolvus
                                                has opened its 3rd office at Symphony IT park</a>
                                            <p className="w-95 sm-w-100 corporate-subtext">Evolvus Technologies has recently expanded by
                                                opening its third
                                                office at Symphony IT Park. This new office strengthens their presence
                                                in the
                                                region, providing enhanced capacity for delivering data informatics
                                                solutions. The
                                                expansion aligns with the company's growth strategy, enabling them to
                                                better serve
                                                their clients while accommodating the increasing demand for their
                                                services. With a
                                                modern infrastructure and a vibrant workspace, this new location is set
                                                to further
                                                support their mission of innovation and excellence in the field of data
                                                informatics.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={isMobileView ? isXSView ? 'slideUpFadeIn' : 'w-50' : 'slideUpFadeIn'} style={{padding: '20px',animationDelay: '800ms'}}>
                                    <div className="card bg-transparent border-0 h-100">
                                        <div className="blog-image position-relative overflow-hidden border-radius-4px">
                                            <a>
                                                <img src={s3BaseURL + '/Images/general/publicationSectionBanner.jpg'} alt/>
                                            </a>
                                        </div>
                                        <div
                                            className="card-body px-0 pt-30px pb-30px xs-pb-15px last-paragraph-no-margin">
                                            <span className="fs-14 text-uppercase"><a href="#"
                                                                                      className="blog-date text-dark-gray fw-600">31 August 2024</a></span>
                                            <a href="#"
                                               className="card-title mb-15px fw-500 fs-18 lh-30 text-dark-gray d-inline-block">Evolvus
                                                has opened its 3rd office at Symphony IT park</a>
                                            <p className="w-95 sm-w-100 corporate-subtext">Evolvus Technologies has recently expanded by
                                                opening its third
                                                office at Symphony IT Park. This new office strengthens their presence
                                                in the
                                                region, providing enhanced capacity for delivering data informatics
                                                solutions. The
                                                expansion aligns with the company's growth strategy, enabling them to
                                                better serve
                                                their clients while accommodating the increasing demand for their
                                                services. With a
                                                modern infrastructure and a vibrant workspace, this new location is set
                                                to further
                                                support their mission of innovation and excellence in the field of data
                                                informatics.</p>
                                        </div>
                                    </div>
                                </div>
                                {isMobileView && (
                                    <div className={isXSView ? '' : 'w-50'} style={{padding: '20px'}}>
                                        <div className="card bg-transparent border-0 slideUpFadeIn" style={{animationDelay: '2s'}}>
                                            <div className="blog-image position-relative overflow-hidden border-radius-4px">
                                                {/*<a href="demo-business-blog-single-modern.html"><img*/}
                                                {/*    src="images/demo-business-blog-03.jpg" alt/></a>*/}
                                                <div  style={{minHeight: 30}}>
                                                    {/*TODO: remove this div with css after adding a tag*/}
                                                </div>
                                            </div>
                                            <div
                                                className="card-body px-0 pt-30px pb-30px xs-pb-15px last-paragraph-no-margin">
                                <span className="fs-14 text-uppercase"><a href="demo-business-blog.html#"
                                                                          className="text-dark-gray fw-600 categories-text">Design</a><a
                                    href="demo-business-blog.html#" className="blog-date">22 August 2023</a></span>
                                                <a href="demo-business-blog-single-modern.html"
                                                   className="card-title mb-15px fw-500 fs-18 lh-30 text-dark-gray d-inline-block">Make
                                                    business easy with beautiful application store</a>
                                                <p className="w-95 w-100">Lorem ipsum simply dummy printing text
                                                    industry...</p>
                                            </div>
                                        </div>
                                        <div className="card bg-transparent border-0 slideUpFadeIn" style={{animationDelay: '2.6s'}}>
                                            <div className="blog-image position-relative overflow-hidden border-radius-4px">
                                                {/*<a href="demo-business-blog-single-modern.html"><img*/}
                                                {/*    src="images/demo-business-blog-04.jpg" alt/></a>*/}
                                                <div  style={{minHeight: 30}}>
                                                    {/*TODO: remove this div with css after adding a tag*/}
                                                </div>
                                            </div>
                                            <div
                                                className="card-body px-0 pt-30px pb-30px xs-pb-15px last-paragraph-no-margin">
                                <span className="fs-14 text-uppercase"><a href="demo-business-blog.html#"
                                                                          className="text-dark-gray fw-600 categories-text">Business</a><a
                                    href="demo-business-blog.html#" className="blog-date">20 August 2023</a></span>
                                                <a href="demo-business-blog-single-modern.html"
                                                   className="card-title mb-15px fw-500 fs-18 lh-30 text-dark-gray d-inline-block">Computers
                                                    are to design as microwaves are to cooking</a>
                                                <p className="w-95 w-100">Lorem ipsum simply dummy printing text
                                                    industry...</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {!isMobileView && <ul className="blog-classic blog-wrapper d-flex grid grid-3col xl-grid-3col lg-grid-3col md-grid-2col sm-grid-2col xs-grid-1col gutter-double-extra-large"
                                                  data-anime="{ &quot;el&quot;: &quot;childs&quot;, &quot;translateY&quot;: [50, 0], &quot;opacity&quot;: [0,1], &quot;duration&quot;: 1200, &quot;delay&quot;: 0, &quot;staggervalue&quot;: 150, &quot;easing&quot;: &quot;easeOutQuad&quot; }">
                                <li className="grid-item">
                                    <div className="card bg-transparent border-0 h-100 slideUpFadeIn"
                                         style={{animationDelay: '1.1s'}}>
                                        <div className="blog-image position-relative overflow-hidden border-radius-4px">
                                            {/*<a href="demo-business-blog-single-modern.html"><img*/}
                                            {/*    src="images/demo-business-blog-03.jpg" alt/></a>*/}
                                            <div  style={{minHeight: 30}}>
                                                {/*TODO: remove this div with css after adding a tag*/}
                                            </div>
                                        </div>
                                        <div
                                            className="card-body px-0 pt-30px pb-30px xs-pb-15px last-paragraph-no-margin">
                                <span className="fs-14 text-uppercase"><a href="demo-business-blog.html#"
                                                                          className="text-dark-gray fw-600 categories-text">Design</a><a
                                    href="demo-business-blog.html#" className="blog-date">22 August 2023</a></span>
                                            <a href="demo-business-blog-single-modern.html"
                                               className="card-title mb-15px fw-500 fs-18 lh-30 text-dark-gray d-inline-block">Make
                                                business easy with beautiful application store</a>
                                            <p className="w-95 w-100">Lorem ipsum simply dummy printing text
                                                industry...</p>
                                        </div>
                                    </div>
                                </li>


                                <li className="grid-item">
                                    <div className="card bg-transparent border-0 h-100 slideUpFadeIn"  style={{animationDelay: '1.4s'}}>
                                        <div className="blog-image position-relative overflow-hidden border-radius-4px">
                                            {/*<a href="demo-business-blog-single-modern.html"><img*/}
                                            {/*    src="images/demo-business-blog-04.jpg" alt/></a>*/}
                                            <div  style={{minHeight: 30}}>
                                                {/*TODO: remove this div with css after adding a tag*/}
                                            </div>
                                        </div>
                                        <div
                                            className="card-body px-0 pt-30px pb-30px xs-pb-15px last-paragraph-no-margin">
                                            <span className="fs-14 text-uppercase">
                                                <a href="demo-business-blog.html#"
                                                   className="text-dark-gray fw-600 categories-text">Business</a>
                                                <a href="demo-business-blog.html#" className="blog-date">20 August 2023</a>
                                            </span>
                                            <a href="demo-business-blog-single-modern.html"
                                               className="card-title mb-15px fw-500 fs-18 lh-30 text-dark-gray d-inline-block">Computers
                                                are to design as microwaves are to cooking</a>
                                            <p className="w-95 w-100">Lorem ipsum simply dummy printing text
                                                industry...</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>}
                        </div>
                    </div>

                </div>
            </section>
        )
    };

    return (
        <div>
            {renderHeader()}
            {renderCards()}
            <Footer {...props}/>
        </div>
    );
}

export default PartnersView;
