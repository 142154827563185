import React, {useRef, useState} from 'react';
import {connect} from "react-redux";
import {Link, withRouter, useLocation} from "react-router-dom";
import useOnClickOutSide from "../../components/Hooks/useClickOutside";
import './menuBar.css'
import {redirectTo} from "../../../utils/helper";
import Hamburger from "./hamburger";
import './header.css'

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

function Header(props) {
    const [openSideMenuBar, setOpenSideMenuBar] = useState(false);
    const {pathname} = useLocation();

    const ref = useRef();
    window.onscroll = function () {
        scrollFunction()
    };

    function scrollFunction() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            document.getElementById("header").style.borderBottom = "0.50px solid #1671DD";
        } else {
            document.getElementById("header").style.borderBottom = "none";
        }
    }

    const handleHamburgerClick = () => {
        setOpenSideMenuBar(!openSideMenuBar)
    };

    const renderData = () => {
        return (
            <div className="dropdown">
                <div className="" id={'link'}>
                    <Link to={{pathname: "/data", state: {Dataheading: 'Liceptordatabase'}}}
                          onClick={() => {
                              window.scrollTo(0, 0);
                              // window.location.assign("/data/liceptor-database");
                          }}>
                        <span
                            className={`${pathname === "/data" || pathname.includes('/data/') ? 'PresentScreen' : 'dropdownbtn'} `}>Data</span>
                    </Link>
                </div>
                <div className="dropdown-content">
                    <Link to={{pathname: "/data/liceptor-database"}}
                          className={'nav-link-text'}
                          onClick={() => {
                              window.scrollTo(0, 0);
                              // window.location.assign("/data/liceptor-database");
                          }}>
                        Liceptor Database</Link>
                    <Link to={{pathname: "/data/oncogen-database"}}
                          className={'nav-link-text'}
                          onClick={() => {
                              window.scrollTo(0, 0);
                              // window.location.assign("/data/liceptor-database");
                          }}>
                        Oncogen Database</Link>
                    <Link to={{pathname: "/data/theme-database"}}
                          className={'nav-link-text'}
                          onClick={() => {
                              window.scrollTo(0, 0);
                              // window.location.assign("/data/liceptor-database");
                          }}>
                        Theme Database</Link>
                    <Link to={{pathname: "/data/proximers-database"}}
                          className={'nav-link-text'}
                          onClick={() => {
                              window.scrollTo(0, 0);
                              // window.location.assign("/data/liceptor-database");
                          }}>
                        Proximers Database</Link>

                    <Link to={{pathname: "/data/adme-database"}}
                          className={'nav-link-text'}
                          onClick={() => {
                              window.scrollTo(0, 0);
                              // window.location.assign("/data/liceptor-database");
                          }}>ADME database</Link>

                    <div className='border-bottom p-3 nav-link-text'>
                        Connector Base
                    </div>

                </div>
            </div>

        );
    };

    const renderBioware = () => {
        return (

            <div className="dropdown">
                <button className="dropdownbtn">
                    <Link to='#'><span
                        className={`${pathname === "/bioware/ai-model" || pathname === "/bioware" || pathname === "/bioware/discovery_platforms" ? 'PresentScreen' : 'dropdownbtn'} `}
                        onClick={(e) => {
                            e.preventDefault();
                            window.scrollTo(0, 0)
                        }}
                    >Bioware</span></Link>
                </button>
                <div className="dropdown-content">
                    <Link to="/bioware/ai-model"
                          onClick={() => {
                              window.scrollTo(0, 0);
                              // window.location.assign('/bioware/ai-model');
                          }}
                    >AI Model</Link>
                    <Link to="/bioware/discovery_platforms"
                          onClick={() => {
                              window.scrollTo(0, 0);
                              // window.location.assign('/bioware/discovery_platforms');
                          }}
                    >Discovery platforms</Link>
                </div>
            </div>

        );
    };

    const renderServices = () => {
        return (
            <div className="dropdown">
                <button className="dropdownbtn p-0"><Link to={""}><span
                    className={`${pathname === "/services/data-curation" ||
                    pathname === '/services/research-info' || pathname === "/services/bio-info" || pathname === "/services/bio-info" || pathname === "/services/chem-info" ||
                    pathname === "/services/clinical" ? 'PresentScreen' : 'dropdownbtn'}`}
                    onClick={(e) => {
                        e.preventDefault();
                        window.scrollTo(0, 0)
                    }}
                >
                    <div style={{cursor: 'pointer', marginTop: -0.6}}
                         className='theme-font-bold cursor headerHover'>
                      <span style={{paddingTop: 5}}
                            className={`${pathname.includes("/services") ? 'PresentScreen' : 'dropdownbtn'} `}> Services </span>
                    </div>

                </span>
                </Link>
                </button>
                <div className="dropdown-content">
                    <Link to="/services/data-curation"
                          className={pathname === "/services/data-curation" ? "nav-link-text-selected" : 'nav-link-text'}
                          onClick={() => {
                              window.scrollTo(0, 0);
                              // window.location.assign("/services/data-curation");
                          }}
                    >Data Curation</Link>
                    <Link to="/services/research-info"
                          className={pathname === "/services/research-info" ? "nav-link-text-selected" : 'nav-link-text'}
                          onClick={() => {
                              window.scrollTo(0, 0);
                          }}
                    >Research Informatics</Link>
                    <Link to="/services/bio-info"
                          className={pathname === "/services/bio-info" ? "nav-link-text-selected" : 'nav-link-text'}
                          onClick={() => {
                              window.scrollTo(0, 0);
                              // window.location.assign("/services/bio-info");
                          }}
                    >Bioinformatics</Link>
                    <Link to="/services/chem-info"
                          className={pathname === "/services/chem-info" ? "nav-link-text-selected" : 'nav-link-text'}
                          onClick={() => {
                              window.scrollTo(0, 0);
                              // window.location.assign("/services/chem-info");
                          }}
                    >Cheminformatics</Link>
                    <Link to="/services/clinical"
                          className={pathname === "/services/clinical" ? "nav-link-text-selected" : 'nav-link-text'}
                          onClick={() => {
                              window.scrollTo(0, 0);
                              // window.location.assign("/services/clinical");
                          }}
                    >Clinical Data</Link>


                </div>
            </div>

        );
    };

    const renderCorporate = () => {
        return (
            <div className="dropdown">
                <button className="dropdownbtn p-0">
                    <Link to={""}>
                        <div style={{cursor: 'pointer'}}
                             className='theme-font-bold cursor headerHover'>
                                <span
                                    className={`${pathname === "/about" || pathname === "/team" || pathname === "/newsAndEvents" || pathname === "/publications" || pathname === "/partners" ? 'PresentScreen' : 'dropdownbtn'} `}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.scrollTo(0, 0)
                                    }} >Corporate</span>
                        </div>

                    </Link>
                </button>
                <div className="dropdown-content">
                    <Link to="/about"
                          className={'nav-link-text'}
                          onClick={() => {
                              window.scrollTo(0, 0);
                              // window.location.assign("/about");
                          }}
                    >About</Link>
                    <Link to="/team"
                          className={'nav-link-text'}
                          onClick={() => {
                              window.scrollTo(0, 0);
                              // window.location.assign("/team");
                          }}
                    >Team</Link>
                    <Link to="/newsAndEvents"
                          className={'nav-link-text'}
                          onClick={() => {
                              window.scrollTo(0, 0);
                              // window.location.assign("/newsAndEvents");
                          }}
                    >News & Events</Link>
                </div>
            </div>

        )
    };

    const renderDesktopHeaderUI = () => {
        return (
            <div className="d-lg-block d-none">
                <div className="d-flex justify-content-between align-items-center block-container"
                     style={{height: "70px"}}>
                    <div className='cursor'>
                        <img src={s3BaseURL + '/Images/general/evolvusLogo.png'}
                             style={{height: '51px'}}
                             onClick={() => redirectTo('/')}
                             alt={''}/>
                    </div>

                    <div id='app-header-nav' className="d-flex align-items-center">
                        <div className="d-flex flex-column align-items-center app-header-nav-menu">
                            <div style={{cursor: 'pointer' }}
                                 onClick={() => {
                                     redirectTo('/');
                                     window.scrollTo(0, 0);

                                 }}
                                 className='theme-font-bold cursor headerHover'>
                                <span style={{paddingTop: 5}}
                                      className={`${pathname === "/" ? 'PresentScreen' : 'dropdownbtn'} `}> Home </span>
                            </div>
                        </div>

                        <div className="d-flex flex-column align-items-center app-header-nav-menu">
                            <div style={{cursor: 'pointer'}}
                                 onClick={() => {
                                     props.history.push('/data');
                                     window.scrollTo(0, 0);
                                 }}
                                 className='theme-font-bold label cursor headerHover'>
                                <span style={{
                                    textTransform: 'capitalize',
                                    paddingTop: 5
                                }}
                                      className={`${pathname === "/data" || pathname === "/data/liceptor-database" || pathname === "/data/oncogen-database" || pathname === "/data/theme-database" || pathname === "/data/proximers-database" || pathname === "/data/adme-database" ? 'PresentScreen' : 'dropdownbtn'} `}> Data </span>
                            </div>
                        </div>

                        <div className="d-flex flex-column align-items-center app-header-nav-menu">
                            <span className={`dropdownbtn px-2`}
                                  style={{
                                      color: 'var(--disable)'
                                  }}> Bioware </span>
                        </div>

                        <div className="d-flex flex-column align-items-center app-header-nav-menu">
                            {renderServices()}
                        </div>

                        <div className="d-flex flex-column align-items-center app-header-nav-menu">
                            <div style={{cursor: 'pointer'}}
                                 onClick={() => {
                                     props.history.push('/careers');
                                     window.scrollTo(0, 0);
                                 }}
                                 className='theme-font-bold label cursor headerHover'>
                                <span style={{
                                    textTransform: 'capitalize',
                                    paddingTop: 6
                                }}
                                      className={`${pathname === "/careers" ? 'PresentScreen' : 'dropdownbtn'} `}> Careers </span>
                            </div>
                        </div>

                        <div className="d-flex flex-column align-items-center app-header-nav-menu">
                            <div style={{cursor: 'pointer'}}
                                 className='theme-font-semiBold label cursor'>
                                <span style={{marginBottom: 10}}>
                                    {renderCorporate()}
                                </span>
                            </div>
                        </div>

                        <div className="d-flex"
                             onClick={() => {
                                 props.history.push('/contactus');
                             }}>
                            <button className="gradient-btn">Contact Us</button>

                        </div>
                    </div>

                </div>
            </div>
        )
    };

    const currentPath = (props.location.pathname);

    return (
        <div id={'header'}
             className='w-100 theme-bg-white position-fixed top-0' style={{zIndex: 999}}>
            {renderDesktopHeaderUI()}

            {/*header Mobile*/}
            <div className="d-flex d-lg-none w-100">
                <div className='w-100'>
                    <div className="d-flex justify-content-between align-items-center block-container"
                         style={{height: "55px"}}>

                        <div className="px-lg-3 px-0 evolvus-label-mobile cursor">
                            <img src={s3BaseURL + '/Images/general/evolvusLogo.png'}
                                 style={{height: '51px'}}
                                 onClick={() => props.history.push('/')}
                                 className='img-fluid  py-1 cursor'
                                 alt={''}/>
                        </div>
                    </div>

                    {openSideMenuBar &&
                    <div className=' w-100 d-flex justify-content-end'>
                        <Hamburger {...props}
                                   handleHamburgerClick={handleHamburgerClick}
                                   useOnClickOutSide={useOnClickOutSide}
                        />
                    </div>
                    }
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(withRouter(Header));
