import React from 'react';
import TeamView from "./teamView";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

function Team(props) {
    return (
        <TeamView
            {...props}
        />
    );
}
const mapStateToProps = (state) => ({
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView,
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,

});

export default connect(mapStateToProps, {})(withTranslation('translations')(Team));









