import React from 'react';
import DataView from "./dataView";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

function Data(props) {
    return (
        <DataView
            {...props}
        />
    );
}
const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(withTranslation('translations')(Data));