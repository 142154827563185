import React from 'react';
import DataHeaderView from "./components/dataHeaderView";
import Footer from "../core/layout/footer/footer";

function DataView(props) {
    const {isXSView} = props;
    return (
        <div>
            <DataHeaderView {...props}/>
            <Footer {...props}/>
        </div>
    );
}

export default DataView;