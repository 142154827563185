import React from 'react';
import {isEmpty} from "../../utils/validations";
let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL + '/Images/data';

function HeaderImage(props) {
    const {currentDatabase} = props;
    return (
        <div className='mb-3'>
            <div className="pt-0 cover-background top-space-margin sm-pb-0"
                 style={{
                     backgroundImage: `url(${s3BaseURL + '/dataBanner.jpg'})`,
                 }}>
                <div className="page-container">
                    <div className="row align-items-end h-200px sm-h-100px ">
                        <div className="col-lg-9 col-md-6 position-relative text-left page-title-extra-large d-flex justify-content-left align-item-end">
                            <div className="justify-content-left header-img-text align-items-center">
                               Data -
                                <span className='header-img-text ps-2'>
                                    {(isEmpty(currentDatabase) ? '' : currentDatabase)}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderImage;