import store from '../store'
import {isEmpty} from './validations';
import React from "react";

export const searchOnWordBoundary = (input, options) => {
    let foundData = [];
    for (let i = 0; i < options.length; i++) {
        let label = options[i].label;
        let labelArr = label.split(" ");

        if (label.toLowerCase().startsWith(input.trim().toLowerCase())) {
            foundData.push(options[i])
        } else {
            let data = labelArr.find((element) => {
                return (element.toLowerCase().startsWith(input.trim().toLowerCase()))
            });
            if (!isEmpty(data)) {
                foundData.push(options[i])
            }
        }
    }

    return foundData;
};

export const debounce = (func, wait, immediate) => {
    let timeout;
    return function () {
        let context = this, args = arguments;
        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export const getRandomId = (idLength) => {
    let stringList = '0123456789abcdefghijklmnopqrstuvwxyz';
    return stringList.split('').map(function (v, i, a) {
        return i > idLength ? null : a[Math.floor(Math.random() * stringList.length)]
    }).join('');
};

export const isChromeBrowser = () => {
    let userAgent = window.navigator.userAgent.toLowerCase();
    let isChrome = /chrome/.test(userAgent);
    let isVender = (window.navigator.vendor === 'Google Inc.');
    return (isChrome && isVender && !isOperaBrowser());
};

export const isOperaBrowser = () => {
    let userAgent = window.navigator.userAgent.toLowerCase();
    return /opr/.test(userAgent);
};

export const isFirefoxBrowser = () => {
    let userAgent = window.navigator.userAgent.toLowerCase();
    return /firefox/.test(userAgent);
};

export const isMobileInLandscape = () => {
    const isMobile = store.getState().appState.deviceInfo.isMobileDevice;
    if (isMobile) {
        return window.innerWidth > (1.5 * window.innerHeight);
    }

    return false;
};

export const isValidEmail = (email, excludeSpecialCharValidation) => {
    let flag = false;
    let localEmail = email?.trim();
    if (isEmpty(localEmail)) {
        return false;
    }

    if (localEmail.includes(" ")) {
        flag = true;
    }

    if (excludeSpecialCharValidation) {
        // ignore special char validation
    } else {
        if (!(/^[a-zA-Z\d0-9@._-]*$/.test(localEmail))) {
            flag = true;
        }
    }

    if (localEmail.includes('@')) {
        //do nothing
    } else {
        return false
    }

    // if(!localEmail.includes('.')){
    //   return  false;
    // } if(!localEmail.includes('@')){
    //   return  false;
    // }

    // let domainName = localEmail.substring(localEmail.indexOf("@") + 1, localEmail.lastIndexOf("."));
    // if(isEmpty(domainName)){
    //   return false;
    // }

    // let donaimType = localEmail.substring(localEmail.lastIndexOf(".") + 1,localEmail.length);
    let domainType = localEmail.split('@');

    if (isEmpty(domainType[0])) {
        return false
    }

    if (isEmpty(domainType[1])) {
        return false
    }

    // if(donaimType.length < 2) {
    //   return false
    // }
    return !flag;
};

export const getFormattedUserName = (user) => {

    if (isEmpty(user)) {
        return "";
    }

    let userName = "";

    if (isEmpty(user?.firstName)) {
        return userName;
    }

    userName = userName + (user?.firstName).trim();

    if (isEmpty(user?.lastName)) {
        return userName;
    }

    userName = userName + " " + (user?.lastName).trim();

    return userName;

};

export const isValidPassword = (password) => {
    let flag = true;
    if (/^([^0-9]*)$/.test(password)) {
        flag = false;
    }
    if (!(/[^a-zA-Z\d]/.test(password))) {
        flag = false;
    }
    if (!/[A-Za-z]+/.test(password)) {
        flag = false;
    }
    return flag;
    // return !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8}$/.test(password);
    ///^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g
};

export const trimStringAttributes = (dataObj) => {

    if (isEmpty(dataObj)) {
        return dataObj;
    }

    if (typeof dataObj === 'string') {
        return dataObj.trim();
    }


    if (typeof dataObj === 'object') {

        Object.keys(dataObj).forEach(function (key) {
            if (typeof dataObj[key] === 'string' && !isEmpty(dataObj[key])) {
                dataObj[key] = dataObj[key].trim();
            }
        });

    }

    return dataObj;

};

export const numberWithCommas = (x) => {
    if (x === 0) {
        return x;
    }
    if (!x) {
        return "";
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getTrimmedValue = (value, trimChars) => {
    if (isEmpty(value)) {
        return ''
    }
    if (value.length > trimChars) {
        return value.slice(0, trimChars) + '...'
    }
    return value
};

export const getHeight = (windowHeight, isMobileView) => {
    if (isMobileView) {
        return windowHeight - 111
    } else {
        return windowHeight - 55
    }
};

export const refreshWindowScrollElement=()=>{
    window.scrollTo(0,0);
};

export const redirectTo=(route)=> {
    window.location.href = route;
};

export const getDevice = () => {
    const userAgent = navigator.userAgent ||  navigator.vendor || window.opera;
    let device = '';

    if (/android/i.test(userAgent)) {
        device = 'Android';
    } else if (/iPad|Macintosh/i.test(userAgent) && 'ontouchend' in document) {
        device = 'iPad';
    } else if (/iPhone|iPod/i.test(userAgent) && !window.MSStream) {
        device = 'iOS';
    } else {
        device = 'unknown';
    }

    return device;
};

export const getFirstErrorId = (orderedErrorFields, errorArray) => {

    // Find the first element in allFields that exists in errorArray
    for (let field of orderedErrorFields) {
        if (errorArray.includes(field)) {
            return field; // Return the first matching field based on allFields order
        }
    }

    return null; // Return null if no matching field is found
};