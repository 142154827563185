import React, {Suspense, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Route, Switch, withRouter} from "react-router-dom";
import {getMetaTagUi} from "./utils/httpHelper";
import {setCurrentPathname, setPrevLocation, getSeoData} from "./core/actions/appActions";
import PrePostLoginRoute from "./core/webRouteWrapper/PrePostLoginRoute";
import HomePage from "./home/home";
import pageNotFound from "./core/components/PageNotFound/pageNotFound";
import DLLoader from "./core/components/Loader/loader";

import Header from "./core/layout/header/header";
import ToastMessage from "./core/components/toastMessage/toastMessage";
import LiceptorDatabase from "./data/components/liceptorDatabase/liceptorDatabase";
import Data from "./data/data";
import ProximersDatabase from "./data/components/proximerDatabase/proximerDatabase";
import ThemeDatabase from "./data/components/themeDatabase/themeDatabase";
import OncogenDatabase from "./data/components/oncogenDatabase/oncogenDatabase";
import AdmeDatabase from "./data/components/admeDatabase/admeDatabase";
import AIModel from "./bioware/AIModel/AIModel";
import DiscoveryPlatforms from "./bioware/discoveryPlatforms/DiscoveryPlatforms";
import dataCuration from "./services/dataCuration/dataCuration";
import researchInformatics from "./services/researchInformatics/researchInformatics";
import bioInformatics from "./services/bioInformatics/bioInformatics";
import clinical from "./services/clinical/clinical";
import chemInformatics from "./services/chemInformatics/chemInformatics";
import Careers from "./careers/careers";
import ContactUs from "./contactUs/contactUs";
import About from "./corporate/about/about";
import Team from "./corporate/team/team";
import NewsEvents from "./corporate/newsEvents/newsEvents";
import Publications from "./corporate/publications/publications";
import Partners from "./corporate/partners/partners";
import BottomMenuOption from "./core/layout/bottomMenuOptions/BottomMenuOption";


const AppNavigator = (props) => {

    const [seoMetaDataState, setSeoMetaDataState] = useState({
        seoMetaData: null,
        isMetaDataCallFired: false,
    });

    useEffect(()=>{
        window.scrollTo(0,0)
    },[props.location.pathname]);

    const {isMobileView} = props;

    const renderContent = () => {
        const {seoMetaData} = seoMetaDataState;

        return (
            <div className="w-100  bg-white" id=""
                 style={{
                     height: '100%',
                 }}>
                {getMetaTagUi(props.location.pathname, seoMetaData, false)}

                <Suspense fallback={(<DLLoader type={"screen"} isVisible={true}/>)}>
                    <Switch>
                        <PrePostLoginRoute exact path="/" component={HomePage}/>
                        <PrePostLoginRoute exact path="/data" component={Data}/>
                        <PrePostLoginRoute exact path="/data/liceptor-database" component={LiceptorDatabase}/>
                        <PrePostLoginRoute exact path="/data/proximers-database" component={ProximersDatabase}/>
                        <PrePostLoginRoute exact path="/data/theme-database" component={ThemeDatabase}/>
                        <PrePostLoginRoute exact path="/data/oncogen-database" component={OncogenDatabase}/>
                        <PrePostLoginRoute exact path="/data/adme-database" component={AdmeDatabase}/>
                        <PrePostLoginRoute exact path="/bioware/ai-model" component={AIModel}/>
                        <PrePostLoginRoute exact path="/bioware/discovery_platforms" component={DiscoveryPlatforms}/>
                        <PrePostLoginRoute exact path="/services/data-curation" component={dataCuration}/>
                        <PrePostLoginRoute exact path="/services/research-info" component={researchInformatics}/>
                        <PrePostLoginRoute exact path="/services/bio-info" component={bioInformatics}/>
                        <PrePostLoginRoute exact path="/services/chem-info" component={chemInformatics}/>
                        <PrePostLoginRoute exact path="/services/clinical" component={clinical}/>
                        <PrePostLoginRoute exact path="/careers"  component={Careers}/>
                        <PrePostLoginRoute exact path="/contactus"  component={ContactUs}/>
                        <PrePostLoginRoute exact path="/about"  component={About}/>
                        <PrePostLoginRoute exact path="/team"  component={Team}/>
                        <PrePostLoginRoute exact path="/newsAndEvents"  component={NewsEvents}/>
                        <PrePostLoginRoute exact path="/publications"  component={Publications}/>
                        <PrePostLoginRoute exact path="/partners"  component={Partners}/>
                        <Route path="*" component={pageNotFound}/>
                    </Switch>
                </Suspense>
                <ToastMessage/>
            </div>
        );
    };

    const renderHeader = () => {
        return (
            <Header {...props}/>
        );
    };

    return (
        <div className={props.isMobileDevice ? 'innerWindowHeight' : 'vh-100'}>
            {renderHeader()}
            <div className='d-flex' style={{
                paddingTop: isMobileView ? '50px' : '80px',
                height: '100%'
            }}>
                {renderContent()}
            </div>
            {
                isMobileView ?  <BottomMenuOption/> :''

            }
        </div>

    );
};


AppNavigator.propTypes = {};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
    windowHeight: state.appState.deviceInfo.windowHeight,
    isDrawerOpen: state.appState.isDrawerOpen,
});

export default connect(mapStateToProps, {
    setPrevLocation
})(withRouter(AppNavigator));