import {getAppEnvironmentVariables, getAuthorizationToken} from "../utils/httpHelper";
import {showSystemErrorBlock, showToastMessage} from "../core/actions/appActions";
import {httpPost} from "../utils/httpHelper";
import store from "../store";
const API_URL = process.env.REACT_APP_API_URL;


const performFileUpload = async (binaryFile, API_url) =>  {
    let statusCode;
    let config = getAppEnvironmentVariables();
    let auth = await getAuthorizationToken();
    let url = API_URL+ API_url;
    return fetch(url,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + auth.token
            },
            body: binaryFile,
        })
        .then(response =>{
            statusCode=response.status;
            return response.json()
        } )
        .then((res) => {
            if (res) {
                if (statusCode === 200) {
                    return {
                        success:true,
                        ...res
                    }
                }
                if (statusCode >= 300 && statusCode < 500){
                    store.dispatch( showToastMessage('warning',res.__error));
                    return null;
                }
            }
        })
};

export async function uploadData(data){
    let url='/service/publicWebsite/career/upload'
    let res = {};
    let files = data;
    if (files) {
        let newFile = files;
        let formData = new FormData();
        formData.append('file', newFile);
        let filePathVal;
        if (typeof files === 'string') {
            filePathVal = files;
        } else {
            filePathVal = await performFileUpload(formData, url);
        }

        if (filePathVal) {
            res = filePathVal;
        } else {
            res = false;
        }
    }

    return res;
}

export const sendCarrierData = (data) => {
    let url = '/service/publicWebsite/career';

    return httpPost(url,data)
        .then((res) => {
            return res;
        })
};
