import React from 'react';
import NewsEventsView from "./newsEventsView";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

function NewsEvents(props) {
    return (
        <NewsEventsView
            {...props}
        />
    );
}

const mapStateToProps = (state) => ({
    isXSView: state.appState.deviceInfo.isXSView,
    isMobileView: state.appState.deviceInfo.isMobileView,
});

export default connect(mapStateToProps, {})(withTranslation('translations')(NewsEvents));
