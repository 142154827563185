import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import {hideToastMessageData} from "../../reducers/appReducer";
import appTheme from "../../../assets/appTheme";

class ToastMessage extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.systemError.isVisible) {
            if (!prevProps.systemError.reloadApp && this.props.systemError.reloadApp) {
                setTimeout(() => {
                    document.location.reload();
                }, 150);
            }
        }
        this.hideToasterMessage()
    }


    reloadApp() {
        window.location.reload();
    }

    hideToasterMessage() {
        const {timeout} = this.props.toastStatus;
        const {closeToastAction} = this.props;

        setTimeout(() => {
            closeToastAction()
        }, timeout);
    }


    render() {
        const {message, isVisible, type} = this.props.toastStatus;
        let messageUI = '';

        switch (type) {
            case 'success':
                messageUI = <div className="toast show align-items-center text-white border-0"
                                 role="alert"
                                 aria-live="assertive"
                                 aria-atomic="true"
                                 style={{
                                     background: appTheme.appColor.success,
                                     width: "auto",
                                     height: "auto",
                                     position: 'fixed',
                                     zIndex: 999,
                                     top: 22,
                                     boxShadow: "none",
                                     fontSize: '14px'
                                 }}
                >
                    <div className="d-flex align-items-center" style={{padding: '6px 16px'}}>
                        <div>
                            <i className="fa-regular fa-circle-check mx-2"
                               style={{
                                   fontSize: 18,
                                   padding: '9px 0px'
                               }}
                            />
                        </div>
                        <div className="toast-body" style={{padding: '8px 0px'}}>
                            {message}
                        </div>
                    </div>
                </div>
                ;
                break;
            case 'danger':
                messageUI = <div className="toast show align-items-center text-white border-0"
                                 role="alert"
                                 aria-live="assertive"
                                 aria-atomic="true"
                                 style={{
                                     background: appTheme.appColor.danger,
                                     minWidth: '27%',
                                     position: 'fixed',
                                     zIndex: 999,
                                     top: 20,
                                     boxShadow: "none"

                                 }}
                >
                    <div className="d-flex">
                        <div className="toast-body">
                            <i className="fa-solid fa-triangle-exclamation mx-2" style={{fontSize: 18}}/>
                            {message}
                        </div>
                    </div>
                </div>;

                break;
            case 'info':
                messageUI = <div className="toast show align-items-center text-white border-0"
                                 role="alert"
                                 aria-live="assertive"
                                 aria-atomic="true"
                                 style={{
                                     background: appTheme.appColor.info,
                                     minWidth: '27%',
                                     position: 'fixed', zIndex: 999,
                                     top: 22,
                                     boxShadow: "none"
                                 }}
                >
                    <div className="d-flex">
                        <div className="toast-body">
                            <i className="fa-solid fa-triangle-exclamation mx-2" style={{fontSize: 18}}/>
                            {message}
                        </div>

                    </div>
                </div>;
                break;
            case 'warning':
                messageUI =
                    <div className="toast show align-items-center text-white border-0"
                         role="alert"
                         aria-live="assertive"
                         aria-atomic="true"
                         style={{
                             background: appTheme.appColor.warning,
                             minWidth: '27%',
                             color: '#FFF',
                             position: 'fixed', zIndex: 999,
                             top: 22,
                             boxShadow: "none"

                         }}
                    >
                        <div className="d-flex">
                            <div className="toast-body">
                                <i className="fa-solid fa-triangle-exclamation mx-2" style={{fontSize: 18}}/>
                                {message}
                            </div>
                        </div>
                    </div>;
                break;
            default:
                break;
        }


        return (
            <div id="snackbar" className='snackbar'
                 style={{
                     vertical: 'top',
                     horizontal: 'top'
                 }}
                 hidden={!isVisible}
            >
                <div className='d-flex justify-content-center align-items-center'
                     style={{
                         fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
                     }}>
                    {messageUI}
                </div>
            </div>
        );
    }
}

ToastMessage.propTypes = {
    id: PropTypes.string,  // for testing purpose
};

const mapStateToProps = (state) => ({
    toastStatus: state.appState.toastStatus,
    systemError: state.appState.systemError,
    isXSView: state.appState.deviceInfo.isXSView
});

const mapDispatchToProps = (dispatch) => ({
    closeToastAction: () => {
        dispatch(hideToastMessageData())
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ToastMessage);
