import React from "react";

const TabUiView = (props) => {

    const {isXSView, isMainScreen} = props;

    const renderDesktopView = () => {
        return(
            <div className="pb-3">
                <ul className=" d-flex flex-wrap gap-3 nav nav-tabs justify-content-center border-0 ">
                    <div className="database-menu">
                        <div data-bs-toggle="tab"
                             aria-selected="false"
                             onClick={() => props.history.push('/data/liceptor-database')}
                             className={` black d-flex align-items-center tab-data-size ${props.location.pathname === '/data/liceptor-database' ? 'active selected' : ((isMainScreen) ? ' list' : '')}`}>
                            <i className="fa-solid fa-briefcase"
                               style={{fontSize: 20}}/><span>Liceptor Database</span>
                        </div>
                    </div>
                    <div className=' database-menu'>
                        <div data-bs-toggle="tab" onClick={() => props.history.push('/data/oncogen-database')}
                             className={`black d-flex align-items-center tab-data-size ${props.location.pathname === '/data/oncogen-database' ? 'active selected' : ((isMainScreen) ? ' list' : '')}`}>
                            <i className="fa-regular fa-compass" style={{fontSize: 22}}/><span>Oncogen Database</span>
                        </div>
                    </div>
                    <div className=' database-menu'>
                        <div data-bs-toggle="tab" onClick={() => props.history.push('/data/theme-database')}
                             className={` black d-flex align-items-center tab-data-size ${props.location.pathname === '/data/theme-database' ? 'active selected' : ((isMainScreen) ? ' list' : '')}`}>
                            <i className="fa-regular fa-compass" style={{fontSize: 22}}/><span>Theme Database</span>
                        </div>
                    </div>
                    <div className=" database-menu">
                        <div data-bs-toggle="tab" onClick={() => props.history.push('/data/proximers-database')}
                             className={` black d-flex align-items-center tab-data-size ${props.location.pathname === '/data/proximers-database' ? 'active selected' : ((isMainScreen) ? ' list' : '')}`}>
                            <i className="fa-regular fa-pen-to-square"
                               style={{fontSize: 22}}/><span>Proximers Database</span>
                        </div>
                    </div>


                    <div className=" database-menu">
                        <div data-bs-toggle="tab" onClick={() => props.history.push('/data/adme-database')}
                             className={`black d-flex align-items-center tab-data-size ${props.location.pathname === '/data/adme-database' ? 'active selected' :  ((isMainScreen) ? ' list' : '')}`}>
                            <i className="fa-solid fa-globe"
                               style={{fontSize: 22}}/><span>ADME Database</span>
                        </div>
                    </div>
                </ul>
            </div>
        )
    };

    const renderMobileView = () => {
        return(
            <div className="section-divider">
                <ul className=" d-flex flex-wrap gap-3 nav nav-tabs justify-content-center border-0 ">
                    <div className="database-menu-mobile">
                        <div data-bs-toggle="tab" aria-selected="false"
                             onClick={() => props.history.push('/data/liceptor-database')}
                             className={` black d-flex align-items-center tab-data-size-mobile ${props.location.pathname === '/data/liceptor-database' ? 'active selected' : ''}`}>
                            <i className="fa-solid fa-briefcase me-2"
                               style={{fontSize: 14}}/><span>Liceptor</span>
                        </div>
                    </div>
                    <div className=' database-menu-mobile'>
                        <div data-bs-toggle="tab" onClick={() => props.history.push('/data/oncogen-database')}
                             className={`black d-flex align-items-center tab-data-size-mobile ${props.location.pathname === '/data/oncogen-database' ? 'active selected' : ''}`}>
                            <i className="fa-regular fa-compass me-2" style={{fontSize: 14}}/><span>Oncogen</span>
                        </div>
                    </div>
                    <div className=' database-menu-mobile'>
                        <div data-bs-toggle="tab" onClick={() => props.history.push('/data/theme-database')}
                             className={` black d-flex align-items-center tab-data-size-mobile ${props.location.pathname === '/data/theme-database' ? 'active selected' : ''}`}>
                            <i className="fa-regular fa-compass me-2" style={{fontSize: 14}}/><span>Theme</span>
                        </div>
                    </div>
                    <div className=" database-menu-mobile">
                        <div data-bs-toggle="tab" onClick={() => props.history.push('/data/proximers-database')}
                             className={` black d-flex align-items-center tab-data-size-mobile ${props.location.pathname === '/data/proximers-database' ? 'active selected' : ''}`}>
                            <i className="fa-regular fa-pen-to-square me-2"
                               style={{fontSize: 14}}/><span>Proximers</span>
                        </div>
                    </div>
                    <div className=" database-menu-mobile">
                        <div data-bs-toggle="tab" onClick={() => props.history.push('/data/adme-database')}
                             className={`black d-flex align-items-center tab-data-size-mobile ${props.location.pathname === '/data/adme-database' ? 'active selected' : ''}`}>
                            <i className="fa-solid fa-globe me-2"
                               style={{fontSize: 14}}/><span>ADME</span>
                        </div>
                    </div>
                </ul>
            </div>
        )
    };

    return isXSView ? renderMobileView() : renderDesktopView()
};

export default TabUiView;