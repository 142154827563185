import React from 'react';
import PublicationsView from "./publicationsView";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

function Publications(props) {

    return (
        <PublicationsView
            {...props}
        />
    );
}
const mapStateToProps = (state) => ({
    isXSView: state.appState.deviceInfo.isXSView,
    isMobileView: state.appState.deviceInfo.isMobileView,
});

export default connect(mapStateToProps, {})(withTranslation('translations')(Publications));
