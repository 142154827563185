import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import HomeView from "./homeView";


const Home = (props) => {
    const ourProductsRef = useRef(null);
    const noiseRef = useRef(null);
    const [isVisibleOurProducts, setIsVisibleOurProducts] = useState(false);
    const [isVisibleNoise, setIsVisibleNoise] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (ourProductsRef.current) {
                const { top } = ourProductsRef.current.getBoundingClientRect();
                const windowHeight = window.innerHeight;

                // Check if the element is in view
                if (top < windowHeight * 0.9) {
                    setIsVisibleOurProducts(true);
                }
            }
            if (noiseRef.current) {
                const { top } = noiseRef.current.getBoundingClientRect();
                const windowHeight = window.innerHeight;

                // Check if the element is in view
                if (top < windowHeight * 0.9) {
                    setIsVisibleNoise(true);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Check once on mount in case it's already in view

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (

        <HomeView
            {...props}
            isVisibleNoise={isVisibleNoise}
            noiseRef={noiseRef}
            ourProductsRef={ourProductsRef}
            isVisibleOurProducts={isVisibleOurProducts}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView,
});

export default connect(mapStateToProps, {})(withTranslation('translations')(Home));