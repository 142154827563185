import React, {useEffect, useState} from 'react';

import ContactUSView from "./contactUsView";
import {connect} from "react-redux";
import {sendContact} from './contactUsAction'

import {withTranslation} from "react-i18next";
import {isEmpty} from "../utils/validations";
import store from '../store';
import {showToastMessage} from "../core/actions/appActions";

const ContactUS = (props) => {
    const [contactForm, setContactForm] = useState({
        name: '',
        email: '',
        mobile: '',
        subject: '',
        massage: '',
        errors: {
            name: '',
            email: '',
        },
        scrollToFieldId: ''
    });

    const handleChanges = (title, value) => {
        setContactForm((prevState) => ({
            ...prevState,
            [title]: value,
            errors: {
                name: title === "name" ? '' : prevState.errors.name,
                email: title === "email" ? '' : prevState.errors.email
            }
        }));
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return !emailRegex.test(email);
    };

    const onBlurContent = (title) => {
        validateChanges(title)
    };

    const validateChanges = (title) => {
        let flag = true;
        let scrollFieldId = '';
        if (title === 'name' || title === 'send') {
            let errors = isEmpty(contactForm.name);
            setContactForm((prevState) => ({
                ...prevState,
                errors: {
                    ...prevState.errors,
                    name: (errors ? 'Please enter name' : '')
                }
            }));
            if (errors){
                flag = false;
                scrollFieldId = 'error-field-name'
            }
        }
        if (title === 'email' || title === 'send') {
            let errors = isValidEmail(contactForm.email);
            let errMsg = '';
            if (contactForm.email === '') {
                errMsg = 'Please enter email'
            } else if (errors) {
                errMsg = 'Please enter valid email'
            }
            setContactForm((prevState) => ({
                ...prevState,
                errors: {
                    ...prevState.errors,
                    email: (errMsg !== '' ? errMsg : '')
                }
            }));
            if (!isEmpty(errMsg)){
                scrollFieldId = 'error-field-email';
                flag = false;
            }
        }
        if(!flag){
            setContactForm((prevState) => ({
                ...prevState,
                scrollToFieldId : scrollFieldId
            }));

        }
        return flag;
    };

    const clearInputs = () => {
        setContactForm((prevState) => ({
            ...prevState,
            name: '',
            email: '',
            mobile: '',
            subject: '',
            massage: '',
            errors: {
                ...prevState.errors,
                name: '',
                email: '',
            }
        }));
    };

    const handleSendMsg = () => {
        const {name, email, mobile, massage, subject} = contactForm;
        if (!validateChanges('send')) {
            return
        }

        let payload = {
            "name": name,
            "email": email,
            "subject": subject,
            "phoneNumber": mobile,
            "message": massage
        };

        sendContact(payload).then(res => {
            if (res?.success) {
                store.dispatch(showToastMessage('success', 'Thank You for Contacting Us.'));
                clearInputs();
            }
        })
    };

    const onFormChange = (value) => {
        setContactForm((prevState) => ({
            ...prevState,
            scrollToFieldId : ''
        }));
    };

    return (

        <ContactUSView
            {...props}
            {...contactForm}
            onBlurContent={onBlurContent}
            handleChanges={handleChanges}
            handleSendMsg={handleSendMsg}
            onFormChange={onFormChange}
        />
    );
};
const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView,
});

export default connect(mapStateToProps, {})(withTranslation('translations')(ContactUS));
