import React from 'react';
import PartnersView from "./partnersView";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

function Partners(props) {
    return (
        <PartnersView
            {...props}
        />
    );
}

const mapStateToProps = (state) => ({
    isXSView: state.appState.deviceInfo.isXSView,
    isMobileView: state.appState.deviceInfo.isMobileView,
});

export default connect(mapStateToProps, {})(withTranslation('translations')(Partners));
