import React, {useEffect} from 'react';
import Footer from "../../core/layout/footer/footer";
import DataCurationComponent from "../../core/components/dataCurationComponent/dataCurationComponent";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL + '/Images';

function AIModelView(props) {


    const renderHeaderUI = () => {
        return (
            <section className="pt-0 cover-background ipad-top-space-margin sm-pb-0"
                     style={{
                         backgroundImage: `url(${s3BaseURL + '/bioWare/aiModelBanner.jpg'})`,
                         marginTop: props.isMobileView ? 0 : -80,
                         height: '400px'
                     }}>
                <div className="container">
                    <div className="row align-items-center justify-content-center h-500px sm-h-300px">
                        <div
                            className="col-12 col-md-6 position-relative text-center page-title-extra-large d-flex flex-wrap flex-column align-items-center justify-content-center slideUpQuickly">
                            <h1 className="mb-20px text-white fw-600 ls-minus-1px">AI Models</h1>
                        </div>
                    </div>
                </div>
            </section>
        )
    };



    const renderUI = () => {
        return (
            <div>
                {renderHeaderUI()}
                <section className="big-section pb-0">
                    <div className="container">
                        <DataCurationComponent />
                    </div>
                </section>

            </div>
        )
    };

    return (
        <div>
            {renderUI()}
            <Footer {...props}/>
            <div className="floating-tab">
                <a href="#modal-popup3" className="popup-with-move-anim">
                    <div className="tab-text">Enquire Now</div>
                </a>
            </div>
        </div>
    );
}

export default AIModelView;